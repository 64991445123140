import { useState } from 'react';

import { navigator } from '../../../navigation/app-router';

import Displayable from '@prahatech/util-react-web/utils/Displayable';
import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

import { Spinner } from '../../../core/components/spinner';
import { humanize } from '../../../core/utils/humanize-date';
import { TableRowAction } from './table-row-action';

export function TableRow({
  alternative,
  form,
  isDownloading,
  isMarkingProcessed,
  markAsProcessed,
  openCompletedFormDocument,
  cancelForm,
  editForm,
}) {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [showMenuActions, setShowMenuActions] = useState(false);

  const uid = form.uid;

  const hasContact = !!(form.patient.contactCode && form.patient.contactNumber);
  const contactUrl = `https://wa.me/${form.patient.contactCode + form.patient.contactNumber}`;

  const reference = form.practiceReference ? form.practiceReference : form.patient.email;

  const isCompleted = form.status.toLowerCase() === 'completed';
  const isProcessed = form.status.toLowerCase() === 'processed';
  const isExpired = new Date(form.expires).getTime() < Date.now();

  const timeToAppointment = humanize(
    new Date(),
    new Date(Displayable.date(form.appointment).utc.format('yyyy-MM-dd')),
    { maxBracket: humanize.brackets.day }
  );

  const appointmentStr = Displayable.date(form.appointment).utc.format('dd-MM-yyyy');
  const createdStr = Displayable.date(form.created).utc.format('dd-MM-yyyy');

  const _navigateToNotes = () => navigator.navigate(`/notes/${form.patient.fileUid}`);

  const _toggleMenuActions = e => {
    e.stopPropagation();
    setShowMenuActions(!showMenuActions);
  };

  const _toggleAdditionalInfo = () => {
    setShowAdditionalInfo(!showAdditionalInfo);
  };

  const _openDocument = e => {
    e.stopPropagation();
    (isCompleted || isProcessed) && !isDownloading && openCompletedFormDocument(uid);
  };

  const _markProcessed = e => {
    e.stopPropagation();
    isCompleted && !isMarkingProcessed && markAsProcessed();
  };

  const _cancelForm = async e => {
    e.stopPropagation();

    window.confirm(`Are you sure you want to cancel this form (${reference})?`) && cancelForm(uid);
  };

  const _editForm = async e => {
    e.stopPropagation();
    editForm(form);
  };

  const _formatMoney = cents => {
    let money = String(cents).replace(/^(\d*?)(\d{0,2})$/, '$1.$2');
    return money.startsWith('.') ? '0' + money : money;
  };

  let totalMoney = null;
  if (form.payment && form.payment.totalCents) totalMoney = _formatMoney(form.payment.totalCents);

  let docpayMoney = null;
  if (form.payment && form.payment.docpayCents) docpayMoney = _formatMoney(form.payment.docpayCents);

  let feeMoney = null;
  if (form.payment && form.payment.feeCents) feeMoney = _formatMoney(form.payment.feeCents);

  let nettMoney = null;
  if (form.payment && form.payment.practiceCents) nettMoney = _formatMoney(form.payment.practiceCents);

  let updatedOnStr = null;
  if (form.payment && form.payment.status === 'PAID' && form.payment.updatedOn)
    updatedOnStr = Displayable.date(form.payment.updatedOn).utc.format('dd-MM-yyyy');

  const $contact = (
    <a href={contactUrl} target='_blank' rel='noopener noreferrer' onClick={e => e.stopPropagation()}>
      <svg className='form-row__action-icon' viewBox='0 0 170 170' xmlns='http://www.w3.org/2000/svg'>
        <path d='M85.0052 9.26423e-05C70.2116 -0.0217611 55.6691 3.82311 42.8194 11.1536C29.9698 18.484 19.2592 29.0454 11.7495 41.7908C4.2398 54.5363 0.191741 69.0231 0.00664565 83.8151C-0.17845 98.6071 3.50584 113.191 10.6943 126.12L0.221732 162.778C-0.0554661 163.75 -0.0679129 164.778 0.185681 165.756C0.439274 166.734 0.949686 167.626 1.66406 168.34C2.37843 169.055 3.27079 169.565 4.24872 169.819C5.22665 170.072 6.2546 170.06 7.2261 169.783L43.8857 159.31C55.2333 165.59 67.8678 169.189 80.8213 169.831C93.7748 170.474 106.703 168.142 118.617 163.016C130.53 157.889 141.111 150.104 149.551 140.256C157.99 130.409 164.063 118.76 167.304 106.202C170.545 93.6448 170.869 80.512 168.25 67.8102C165.631 55.1084 160.139 43.1746 152.195 32.9232C144.251 22.6718 134.065 14.3749 122.419 8.66821C110.773 2.96148 97.9746 -0.00362083 85.0052 9.26423e-05V9.26423e-05ZM85.0052 158.67C71.8085 158.65 58.8603 155.08 47.5182 148.334C46.8543 147.942 46.1173 147.689 45.3524 147.592C44.5875 147.494 43.8107 147.554 43.0697 147.767L13.9188 156.097L22.2492 126.936C22.4624 126.195 22.5221 125.418 22.4246 124.653C22.3272 123.888 22.0747 123.151 21.6825 122.488C13.4257 108.505 10.0241 92.1853 12.0072 76.0687C13.9903 59.9522 21.2469 44.9436 32.6472 33.38C44.0474 21.8163 58.9514 14.3465 75.0385 12.1337C91.1256 9.92091 107.493 13.0893 121.592 21.1455C135.691 29.2017 146.731 41.6936 152.992 56.6761C159.253 71.6586 160.384 88.2909 156.21 103.983C152.035 119.675 142.788 133.547 129.91 143.438C117.031 153.329 101.244 158.684 85.0052 158.67V158.67Z' />
        <path d='M131.99 104.484C131.814 104.331 111.929 89.6712 105.191 90.8443C101.791 91.4449 99.9547 93.638 96.8945 97.2817C96.3618 97.9107 95.9198 98.4151 95.5458 98.8628C94.571 98.4944 93.3753 97.9957 92.0039 97.3327C83.5784 93.2324 76.7719 86.4241 72.6739 77.9977C72.0109 76.632 71.5122 75.4363 71.1438 74.4616C71.5972 74.0649 72.1299 73.6172 72.7306 73.1072C76.3687 70.0585 78.5619 68.2168 79.1626 64.8111C80.3356 58.1073 65.6752 38.1772 65.5222 38.0129C64.6663 36.8456 63.5609 35.8839 62.2865 35.1977C61.0121 34.5115 59.6007 34.1181 58.1551 34.0461C51.1338 34.0461 34.0479 55.7386 34.0479 60.816C34.0479 61.6943 34.3482 82.7464 60.6259 109.488C87.272 135.674 108.325 136.003 109.209 136.003C114.286 136.003 136.008 118.889 136.008 111.868C135.933 110.415 135.533 108.997 134.838 107.72C134.143 106.442 133.169 105.336 131.99 104.484V104.484ZM108.353 124.55C104.358 124.052 88.394 120.884 68.622 101.458C49.037 81.5224 45.9031 65.4967 45.4498 61.666C49.0919 56.271 53.2441 51.2384 57.8491 46.6377C61.3998 51.7869 64.603 57.1672 67.4376 62.7427C66.8086 63.3094 66.0209 63.9554 65.4598 64.4427C63.5017 65.9286 61.7151 67.6277 60.1329 69.5088L58.7502 71.4355L59.1582 73.7759C59.8848 76.9639 61.0041 80.0494 62.4904 82.9618C67.7059 93.6724 76.3615 102.326 87.0736 107.539C89.9851 109.029 93.071 110.15 96.2598 110.876L98.6003 111.279L100.521 109.907C102.403 108.324 104.104 106.538 105.593 104.581C106.092 103.986 106.727 103.215 107.293 102.603C112.869 105.429 118.25 108.624 123.399 112.168C118.79 116.766 113.752 120.913 108.353 124.55V124.55Z' />
      </svg>
    </a>
  );

  const $process = isMarkingProcessed ? (
    <Spinner size='1em' color='primary' />
  ) : (
    <svg className='form-row__action-icon' viewBox='0 0 174 176' xmlns='http://www.w3.org/2000/svg'>
      <path d='M161.5 155.4C161.5 161.1 156.7 165.9 151 165.9H20.2C14.5 165.9 9.7 161.1 9.7 155.4V24.9C9.7 19.2 14.5 14.4 20.2 14.4H110.2V5.40005H20.2C9.4 5.40005 0.699997 14.1 0.699997 24.9V155.7C0.699997 166.5 9.4 175.2 20.2 175.2H151C161.8 175.2 170.5 166.5 170.5 155.7V69H161.5V155.4ZM40 67.5L34 74.4L78.1 112.2L173.5 6.30005L166.9 0.300049L77.8 99.9L40 67.5Z' />
    </svg>
  );

  const $document = isDownloading ? (
    <Spinner size='1em' color='primary' />
  ) : (
    <svg className='form-row__action-icon' viewBox='0 0 170 170' xmlns='http://www.w3.org/2000/svg'>
      <path d='M137.5 140.049H30.5V131.049H137.5V140.049Z' />
      <path d='M30.5 104.149H137.5V95.1493H30.5V104.149Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M114.5 0.149281H19.5C8.7 0.149281 -9.53674e-07 8.84928 -9.53674e-07 19.6493V150.449C-9.53674e-07 161.249 8.7 169.949 19.5 169.949H150.3C161.1 169.949 169.8 161.249 169.8 150.449V58.5493L114.5 0.149281ZM160.8 150.149C160.8 155.849 156 160.649 150.3 160.649H19.5C13.8 160.649 9 155.849 9 150.149V19.6493C9 13.9493 13.8 9.14928 19.5 9.14928H100.5L101 53.0493C101 63.8493 109.7 72.5493 120.5 72.5493H160.8V150.149ZM137.5 104.149H30.5V95.1493H137.5V104.149ZM137.5 140.049H30.5V131.049H137.5V140.049ZM109.5 9.14928L160.8 63.7493L120.5 63.5493C114.8 63.5493 110 58.7493 110 53.0493L109.5 9.14928Z'
      />
    </svg>
  );

  const $notes = (
    <svg className='form-row__action-icon' viewBox='0 0 233 236' xmlns='http://www.w3.org/2000/svg'>
      <path d='M148.638 81.9762L125.485 107.284C120.663 112.573 128.714 119.917 133.539 114.626L156.755 89.2461C159.241 91.2501 161.786 93.1055 164.319 94.7493C186.752 109.304 208.5 101.927 196.972 76.9814L212.82 54.7955C218.902 55.6954 224.34 54.4565 228.112 50.8423C248.617 31.0566 198.541 -15.5156 179.599 5.19612C175.81 9.33432 174.882 15.1677 176.407 21.4994L155.715 39.2628C129.876 29.9688 124.432 52.277 140.927 73.3358C143.228 76.2733 145.841 79.1897 148.638 81.9762ZM187.65 12.5396C196.439 2.93616 229.512 33.1894 220.732 42.8162C207.857 49.3894 180.005 20.8896 187.65 12.5396Z' />
      <path d='M153.5 26.5C156.538 26.5 159 24.0376 159 21C159 17.9624 156.538 15.5 153.5 15.5V26.5ZM54 21V26.5V21ZM24 51L29.5 51L29.5 51L24 51ZM24 181.5H29.5H24ZM214 181.5L208.5 181.5L214 181.5ZM219.5 76C219.5 72.9624 217.038 70.5 214 70.5C210.962 70.5 208.5 72.9624 208.5 76H219.5ZM153.5 15.5H54V26.5H153.5V15.5ZM54 15.5C34.904 15.5 18.4999 32.5212 18.5 51L29.5 51C29.5 38.4788 41.0959 26.5 54 26.5L54 15.5ZM18.5 51V181.5H29.5V51H18.5ZM18.5 181.5C18.5 194.883 21.9968 204.316 29.153 210.141C36.0601 215.763 45.2001 217 54 217V206C45.7998 206 39.9399 204.737 36.097 201.609C32.5032 198.684 29.5 193.117 29.5 181.5H18.5ZM54 217H184V206H54V217ZM184 217C195.309 217 204.361 213.139 210.549 206.41C216.666 199.757 219.5 190.83 219.5 181.5L208.5 181.5C208.5 188.67 206.333 194.743 202.451 198.965C198.639 203.111 192.691 206 184 206V217ZM219.5 181.5V76H208.5V181.5H219.5Z' />
      <path d='M25.5 46.5C28.5376 46.5 31 44.0376 31 41C31 37.9624 28.5376 35.5 25.5 35.5V46.5ZM6 70L11.5 70L11.5 70L6 70ZM6 191L0.5 191L6 191ZM201 210.5C201 207.462 198.538 205 195.5 205C192.462 205 190 207.462 190 210.5H201ZM25.5 35.5C20.0294 35.5 15.3782 36.4417 11.5765 38.5321C7.6766 40.6765 5.0866 43.802 3.43855 47.3939C1.84502 50.8669 1.15582 54.7161 0.826043 58.4452C0.495948 62.1778 0.499985 66.1822 0.5 70L11.5 70C11.5 66.0678 11.504 62.5722 11.7833 59.4142C12.0629 56.2527 12.5924 53.8206 13.4364 51.9811C14.2259 50.2605 15.2921 49.0423 16.8766 48.171C18.5593 47.2458 21.2206 46.5 25.5 46.5V35.5ZM0.5 70L0.5 191H11.5L11.5 70H0.5ZM0.5 191C0.5 216.038 19.4624 236 46.5 236V225C25.5376 225 11.5 209.962 11.5 191L0.5 191ZM46.5 236H166V225H46.5V236ZM166 236C170.927 236 175.526 236.004 179.509 235.668C183.473 235.334 187.377 234.629 190.76 232.894C194.349 231.054 197.057 228.207 198.76 224.226C200.378 220.442 201 215.849 201 210.5H190C190 215.151 189.434 218.058 188.646 219.899C187.943 221.543 187.026 222.446 185.74 223.106C184.248 223.871 182.027 224.416 178.584 224.707C175.161 224.996 171.073 225 166 225V236Z' />
    </svg>
  );

  const $edit = (
    <svg className='form-row__action-icon' viewBox='0 0 170 170' xmlns='http://www.w3.org/2000/svg'>
      <path d='M168.818 33.5629L136.439 1.18191C136.062 0.806278 135.615 0.50855 135.123 0.30575C134.632 0.102951 134.105 -0.000943497 133.573 6.45591e-06H50.5437C45.7295 0.00429353 41.1137 1.9187 37.7096 5.32298C34.3054 8.72727 32.3911 13.3432 32.3868 18.1576V34.7529L31.205 33.5629C30.829 33.1859 30.3823 32.8869 29.8906 32.6828C29.3989 32.4788 28.8717 32.3737 28.3394 32.3737C27.807 32.3737 27.2798 32.4788 26.7881 32.6828C26.2964 32.8869 25.8497 33.1859 25.4738 33.5629L1.18909 57.8486C0.812166 58.2246 0.513117 58.6712 0.309074 59.163C0.10503 59.6547 0 60.1819 0 60.7143C0 61.2467 0.10503 61.7739 0.309074 62.2656C0.513117 62.7574 0.812166 63.204 1.18909 63.58L32.3868 94.771V151.842C32.3911 156.657 34.3054 161.273 37.7096 164.677C41.1137 168.081 45.7295 169.996 50.5437 170H151.843C156.657 169.996 161.273 168.081 164.677 164.677C168.081 161.273 169.996 156.657 170 151.842V36.4286C170.001 35.8967 169.897 35.3698 169.694 34.8781C169.491 34.3864 169.194 33.9394 168.818 33.5629V33.5629ZM137.62 13.8186L156.182 32.381H137.62V13.8186ZM97.146 110.961V129.524H78.5844L21.9201 72.8571L40.4817 54.2948L97.146 110.961ZM28.3394 42.1519L34.7586 48.5714L16.197 67.1338L9.77777 60.7143L28.3394 42.1519ZM151.843 161.905H50.5437C47.8764 161.9 45.3196 160.839 43.4335 158.953C41.5475 157.067 40.486 154.51 40.4817 151.842V102.866L74.0431 136.437C74.4197 136.813 74.8666 137.11 75.3583 137.313C75.85 137.516 76.3768 137.62 76.9087 137.619H101.193C102.267 137.619 103.296 137.193 104.055 136.434C104.814 135.674 105.241 134.645 105.241 133.571V109.286C105.242 108.754 105.138 108.227 104.935 107.735C104.732 107.244 104.435 106.797 104.059 106.42L40.4817 42.8481V18.1576C40.486 15.4902 41.5475 12.9333 43.4335 11.0472C45.3196 9.16104 47.8764 8.09953 50.5437 8.09524H129.526V36.4286C129.526 37.5021 129.952 38.5316 130.711 39.2907C131.47 40.0498 132.5 40.4762 133.573 40.4762H161.905V151.842C161.901 154.51 160.839 157.067 158.953 158.953C157.067 160.839 154.51 161.9 151.843 161.905V161.905Z' />
    </svg>
  );

  const $cancel = (
    <svg viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' alt={'Cancel form'}>
      <path
        d='M9 18C7.21997 18 5.47991 17.4722 3.99987 16.4832C2.51983 15.4943 1.36628 14.0887 0.685088 12.4442C0.00389957 10.7996 -0.17433 8.99002 0.172937 7.24419C0.520203 5.49836 1.37737 3.89471 2.63604 2.63604C3.89471 1.37737 5.49836 0.520203 7.24419 0.172937C8.99002 -0.17433 10.7996 0.00389957 12.4442 0.685088C14.0887 1.36628 15.4943 2.51983 16.4832 3.99987C17.4722 5.47991 18 7.21997 18 9C18 10.1819 17.7672 11.3522 17.3149 12.4442C16.8626 13.5361 16.1997 14.5282 15.364 15.364C14.5282 16.1997 13.5361 16.8626 12.4442 17.3149C11.3522 17.7672 10.1819 18 9 18ZM9 1.31708C7.48046 1.31708 5.99505 1.76767 4.7316 2.61188C3.46815 3.45609 2.48341 4.656 1.9019 6.05987C1.3204 7.46375 1.16825 9.00853 1.4647 10.4989C1.76115 11.9892 2.49288 13.3582 3.56735 14.4327C4.64183 15.5071 6.0108 16.2389 7.50114 16.5353C8.99148 16.8317 10.5363 16.6796 11.9401 16.0981C13.344 15.5166 14.5439 14.5319 15.3881 13.2684C16.2323 12.005 16.6829 10.5195 16.6829 9C16.6829 6.96236 15.8735 5.00818 14.4327 3.56735C12.9918 2.12653 11.0376 1.31708 9 1.31708Z'
        fill='#FF9999'
      />
      <path
        d='M12.8415 13.5C12.6669 13.4998 12.4995 13.4304 12.3761 13.3068L4.69317 5.62391C4.57685 5.49907 4.51352 5.33396 4.51653 5.16335C4.51954 4.99274 4.58866 4.82997 4.70931 4.70931C4.82997 4.58866 4.99274 4.51954 5.16335 4.51653C5.33396 4.51352 5.49907 4.57685 5.62391 4.69317L13.3068 12.3761C13.3988 12.4682 13.4614 12.5855 13.4868 12.7132C13.5122 12.8408 13.4991 12.9731 13.4493 13.0934C13.3995 13.2137 13.3152 13.3165 13.207 13.3888C13.0988 13.4612 12.9716 13.4999 12.8415 13.5Z'
        fill='#FF9999'
      />
      <path
        d='M5.15854 13.5C5.02838 13.4999 4.90117 13.4612 4.79298 13.3888C4.68479 13.3165 4.60047 13.2137 4.55068 13.0934C4.50088 12.9731 4.48784 12.8408 4.5132 12.7132C4.53857 12.5855 4.60119 12.4682 4.69317 12.3761L12.3761 4.69317C12.5009 4.57685 12.6661 4.51352 12.8367 4.51653C13.0073 4.51954 13.17 4.58866 13.2907 4.70931C13.4113 4.82997 13.4805 4.99274 13.4835 5.16335C13.4865 5.33396 13.4232 5.49907 13.3068 5.62391L5.62391 13.3068C5.50052 13.4304 5.33314 13.4998 5.15854 13.5Z'
        fill='#FF9999'
      />
    </svg>
  );

  return (
    <div className='form-row'>
      <div
        className={alternative ? 'form-row__main-info' : 'form-row__main-info alternative'}
        onClick={_toggleAdditionalInfo}
        onMouseLeave={() => setShowMenuActions(false)}
      >
        <div className='form-row__dropdown'>{showAdditionalInfo ? '▼' : '►'}</div>
        <div className='form-row__practice-reference'>{reference}</div>
        <div className='form-row__form'>{form.doctor.formLabel}</div>
        <div className='form-row__appointment'>
          {appointmentStr}
          <span className='form-row__to-appointment'>( {timeToAppointment} )</span>
        </div>
        <div className='form-row__amount'>{totalMoney && `R ${totalMoney}`}</div>
        <div className={`form-row__status form-row__status--${form.status.toLowerCase()}`}>{form.status}</div>

        <div className='form-row__actions'>
          <div className='form-row__actions__item'>
            <TableRowAction
              tooltipText={'WhatsApp'}
              className={'form-row__action form-row__action--enabled'}
              displayCondition={hasContact}
              actionChildren={$contact}
            />
          </div>

          <div className='form-row__actions__item'>
            <TableRowAction
              tooltipText={'Process'}
              onClick={_markProcessed}
              className={`form-row__action form-row__action--${isCompleted ? 'enabled' : 'disabled'}`}
              displayCondition={isCompleted}
              actionChildren={$process}
            />
          </div>

          <div className='form-row__actions__item'>
            <TableRowAction
              tooltipText={'Document'}
              onClick={_openDocument}
              className={`form-row__action form-row__action--${isCompleted || isProcessed ? 'enabled' : 'disabled'}`}
              displayCondition={isCompleted || isProcessed}
              actionChildren={$document}
            />
          </div>

          <div className='form-row__actions__item'>
            {(isCompleted || isProcessed || !(isCompleted || isProcessed || isExpired)) && (
              <div
                onClick={_toggleMenuActions}
                className={`form-row__actions__menu form-row__action form-row__action--enabled`}
              >
                <svg className='form-row__action-icon' viewBox='0 0 75 263' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M75 225C75 245.625 58.125 262.5 37.5 262.5C16.875 262.5 0 245.625 0 225C0 204.375 16.875 187.5 37.5 187.5C58.125 187.5 75 204.375 75 225ZM75 131.25C75 151.875 58.125 168.75 37.5 168.75C16.875 168.75 0 151.875 0 131.25C0 110.625 16.875 93.75 37.5 93.75C58.125 93.75 75 110.625 75 131.25ZM75 37.5C75 58.125 58.125 75 37.5 75C16.875 75 0 58.125 0 37.5C0 16.875 16.875 0 37.5 0C58.125 0 75 16.875 75 37.5Z' />
                </svg>

                {showMenuActions && (
                  <div className='actions__list'>
                    <div className='list__item'>
                      <TableRowAction
                        tooltipText={'Notes'}
                        onClick={_navigateToNotes}
                        className={'form-row__action form-row__action--enabled'}
                        displayCondition={isCompleted || isProcessed}
                        actionChildren={$notes}
                      />
                    </div>

                    <div className='list__item'>
                      <TableRowAction
                        tooltipText={'Edit'}
                        onClick={_editForm}
                        className={'form-row__action form-row__action--enabled'}
                        displayCondition={!(isCompleted || isProcessed || isExpired)}
                        actionChildren={$edit}
                      />
                    </div>

                    <div className='list__item'>
                      <TableRowAction
                        tooltipText={'Delete'}
                        onClick={_cancelForm}
                        className={'form-row__action form-row__action--enabled'}
                        displayCondition={!(isCompleted || isProcessed || isExpired)}
                        actionChildren={$cancel}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {showAdditionalInfo && (
        <div className={alternative ? 'form-row__additional-info' : 'form-row__additional-info alternative'}>
          <table className='form-row__personal-info'>
            <tbody>
              <tr>
                <td className='form-row__personal-info__title'>Name</td>
                {form.patient.firstname && form.patient.surname ? (
                  <td className='form-row__data'>{`${form.patient.firstname} ${form.patient.surname}`}</td>
                ) : (
                  <td className='form-row__data--no-data'>Awaiting form completion</td>
                )}
              </tr>
              <tr>
                <td className='form-row__personal-info__title'>Email</td>
                <td className='form-row__data'>
                  <a href={`mailto:${form.patient.email}`} className='link' onClick={e => e.stopPropagation()}>
                    {form.patient.email}
                  </a>
                </td>
              </tr>
              <tr>
                <td className='form-row__personal-info__title'>Mobile</td>
                {hasContact ? (
                  <td className='form-row__data'>
                    <a
                      href={`tel:+${form.patient.contactCode}${form.patient.contactNumber}`}
                      className='link'
                      onClick={e => e.stopPropagation()}
                    >
                      {`+${form.patient.contactCode} ${form.patient.contactNumber}`}
                    </a>
                  </td>
                ) : (
                  <td className='form-row__data--no-data'>Not provided</td>
                )}
              </tr>
              <tr>
                <td className='form-row__personal-info__title'>ID</td>
                {form.patient.idNumber && form.patient.idType ? (
                  <td className='form-row__data'>{`${form.patient.idNumber} (${form.patient.idType})`}</td>
                ) : (
                  <td className='form-row__data--no-data'>Awaiting form completion</td>
                )}
              </tr>
              <tr>
                <td className='form-row__personal-info__title'>Created On</td>
                <td className='form-row__data'>{createdStr}</td>
              </tr>
            </tbody>
          </table>

          {form.payment && form.payment.hasCharge && (
            <table className='form-row__payment-info'>
              <tbody>
                <tr>
                  <td className='form-row__payment-info__title'>Gross</td>
                  {totalMoney ? (
                    <td className='form-row__data'>R {totalMoney}</td>
                  ) : (
                    <td className='form-row__data--no-data'>Awaiting payment</td>
                  )}
                </tr>
                <tr>
                  <td className='form-row__payment-info__title'>Gateway Fee</td>
                  {feeMoney ? (
                    <td className='form-row__data'>R -{feeMoney}</td>
                  ) : form.payment.itnReference ? (
                    <td className='form-row__data--no-data'>-</td>
                  ) : (
                    <td className='form-row__data--no-data'>Awaiting payment</td>
                  )}
                </tr>
                <tr>
                  <td className='form-row__payment-info__title'>Docpay Fee</td>
                  {docpayMoney ? (
                    <td className='form-row__data'>R -{docpayMoney}</td>
                  ) : form.payment.itnReference ? (
                    <td className='form-row__data--no-data'>-</td>
                  ) : (
                    <td className='form-row__data--no-data'>Awaiting payment</td>
                  )}
                </tr>
                <tr>
                  <td className='form-row__payment-info__title'>Nett</td>
                  {nettMoney ? (
                    <td className='form-row__data'>R {nettMoney}</td>
                  ) : (
                    <td className='form-row__data--no-data'>Awaiting payment</td>
                  )}
                </tr>
                {form.payment && form.payment.itnReference && (
                  <tr>
                    <td className='form-row__payment-info__title'>Transaction Ref.</td>
                    <td className='form-row__data'>{form.payment.itnReference}</td>
                  </tr>
                )}
                {updatedOnStr && (
                  <tr>
                    <td className='form-row__payment-info__title'>Date paid</td>
                    <td className='form-row__data'>{updatedOnStr}</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
}

const scope = '.form-row';
const styles = `
{
  width: 100%;
  font-preset: 0.8em light;
  color: conf(color, black);
}

.form-row__main-info {
  height: 46px;
  background-color: conf(color, white);
  display: flex;
  flex-direction: row;
  padding: 1em 3em 1em 3em;
  border-top: 1px solid conf(color, litGrey);
}

.alternative {
  background-color: conf(color, lighterGrey);
}

.form-row__dropdown {
  display: flex;
  align-items: center;
  width: 30px;
  min-width: 30px;
  text-align: left;
  font-size: .7em;
  color: conf(color, lightGrey);
  cursor: pointer;
}

.form-row__practice-reference {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 7;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.form-row__form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 5;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.form-row__appointment {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 180px;
  text-align: center;
}

.form-row__to-appointment {
  display: block;
  font-size: 1em;
  color: conf(color, grey);
  padding-top: 0em;
  padding-left: 1ch;
}

.form-row__amount {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  text-align: center;
}

.form-row__status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 120px;
  text-align: center;
}

.form-row__status--created {
  color: conf(color, grey);
}

.form-row__status--completed {
  color: conf(color, primary);
}

.form-row__status--processed {
  color: conf(color, litPrimary);
}

.form-row__actions {
  width: 100px;
  display: grid;
  grid-template-columns: 20px 20px 20px 21px;
  grid-gap: 7px;
}

.form-row__actions__item {
  display: flex;
  justify-content: center;
}

.form-row__action {
  display: flex;
  justify-content: center;
  height: 18px;
  width: 18px;
}

.form-row__action-icon {
  width: 100%;
  height: 100%;
}

.form-row__action--disabled {
  cursor: not-allowed;
}

.form-row__action--disabled .form-row__action-icon {
  fill: conf(color, lightGrey);
}

.form-row__action--enabled {
  cursor: pointer;
}

.form-row__action--enabled .form-row__action-icon {
  fill: conf(color, primary);
}

.form-row__actions__menu {
  position: relative;
}

.actions__list {
  height: 38px;
  position: absolute;
  top: 23px;
  left: -90px;
  display: grid;
  grid-template-columns: 30px 30px 30px;
  grid-gap: 2px;
  padding: 10px;
  box-shadow: 2px 2px 10px conf(color, lightGrey);
  border-radius: 5px;
  background-color: conf(color, white);
  z-index: 25;
}

.list__item {
  display: flex;
  justify-content: center;
}

.form-row--empty {
  font-preset: 1em light;
  text-align: center;
  padding-top: 2em;
}

.form-row__additional-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.5em 3em 3em calc(3em + 30px);
  border-top: 1px solid conf(color, litGrey);
}

.form-row__additional-info table {
  width: 400px;
}

.form-row__personal-info__title {
  width: 120px;
  font-preset: 1em normal;
  padding: 5px 30px 5px 0;
}

.form-row__payment-info__title {
  width: 150px;
  font-preset: 1em normal;
  padding: 5px 30px 5px 0;
}

.form-row__data {
  width: 300px;
  padding: 5px 15px 5px 5px;
}

.form-row__data--no-data {
  font-preset: 0.9em light;
  color: conf(color, lightGrey);
  vertical-align: middle;
  padding: 0;
  padding-left: 5px;
}

.link {
  color: conf(color, primary);
  text-decoration: none;
  border-bottom: 1px dotted conf(color, primary);
  padding-bottom: 1px;
}
`;

CustomStyleSheet.create(styles, scope);
