import { useState, useEffect } from 'react';
import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';
import useForm from '@prahatech/util-react-web/form/useForm';
import { AuthenticationProvider } from '../../providers';
import { navigator } from '../../navigation/app-router';
import { Button } from '../../core/components';
import is from '@prahatech/util-react-web/utils/is';

const _navigateForward = () => navigator.navigate('/forms');

export function LoginScreen() {
  const form = useForm(state => {
    setFormState(state);
    form.showErrors();
  });

  const [formState, setFormState] = useState(form.getState());

  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);

  const Field = form.Field;

  const _authenticate = async () => {
    setIsLoading(true);

    document.title = 'docpay - Logging in';

    const { email, password } = formState.values;

    const result = await AuthenticationProvider.login(email, password);

    if (!result.ok) {
      setIsLoading(false);
      setHasErrored(true);

      return console.error(result.errors);
    }

    await AuthenticationProvider.getToken();

    document.title = 'docpay - Logged in';

    _navigateForward();
  };

  const _validateEmail = email => is.email(email) || 'This has to be a valid email address.';

  useEffect(() => {
    document.title = 'docpay - Login';

    Promise.resolve()
      .then(AuthenticationProvider.isAuthenticated)
      .then(isAuthenticated => {
        if (!isAuthenticated) return;

        document.title = 'docpay - Logged in';

        setTimeout(() => _navigateForward());
      })
      .catch(e => console.error(e));
  });

  return (
    <div className='login-screen'>
      <div className='login-screen__box'>
        <div className='login-screen__box__title'>Login</div>

        <Field name='email' type='text' label='Email address' validate={_validateEmail} />
        <Field name='password' type='text' shouldMask={true} label='Password' />

        {!isLoading && hasErrored && (
          <span className='error-message'>Please make sure you've provided the correct credentials.</span>
        )}

        <Button label='Sign in' onPress={_authenticate} loading={isLoading} disabled={!formState.valid} />
      </div>
    </div>
  );
}

const scope = '.login-screen';
const styles = `
  {
    height: 100%;
    width: 100%;
    min-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-screen__box {
    min-width: 400px;
    max-width: 500px;
    width: 40%;
    padding: 3em 4em 2em 4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    box-shadow: 
      3px 3px 3px rgba(0, 0, 0, 0.02),
      inset 2px 2px 5px rgba(0, 0, 0, 0.05);
  }

  .login-screen__box__title {
    font-size: 2em;
    text-align: left;
    padding-bottom: 1.2em;
    color: conf(color, primary);
  }

  .error-message {
    color: conf(color, warn);
    font-preset: .8em light;
  }
`;

CustomStyleSheet.create(styles, scope);
