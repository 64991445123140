import { useState, useEffect } from 'react';

import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';
import useForm from '@prahatech/util-react-web/form/useForm';
import is from '@prahatech/util-react-web/utils/is';

import { DoctorProvider, FormProvider } from '../../../providers';
import { Spinner } from '../../../core/components/spinner';
import { Button } from '../../../core/components';

import { navigator } from '../../../navigation/app-router';

const _navigateToError = () => navigator.navigate(`/feedback/kaboom-kapow`);

const _toRand = value => {
  if (!value) return undefined;

  let rands = String(value).replace(/^(\d*?)(\d{0,2})$/, '$1.$2');
  rands = rands.startsWith('.') ? '0' + rands : rands;

  return rands;
};

export function EditForm({ editForm, cancelEditing, cancelForm, refreshForms }) {
  const [isSettingUp, setIsSettingUp] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [formConfig, setFormConfig] = useState(undefined);
  const [hasDifference, setHasDifference] = useState(false);

  const contactCode = editForm.patient.contactCode;
  const contactNumber = editForm.patient.contactNumber;

  const values = {
    practiceReference: editForm.practiceReference || '',
    email: editForm.patient.email,
    contact: { code: contactCode || '', number: contactNumber || '' },
    appointment: editForm.appointment,
    amount: editForm.payment && editForm.payment.totalCents ? _toRand(editForm.payment.totalCents) : undefined,
  };

  const _checkDifference = state => {
    const hasContactDifference =
      !!values.contact.code !== !!state.values.contact.code ||
      !!values.contact.number !== !!state.values.contact.number ||
      values.contact.code !== state.values.contact.code ||
      values.contact.number !== state.values.contact.number;

    const hasDifference =
      hasContactDifference ||
      values.email !== state.values.email ||
      values.appointment !== state.values.appointment ||
      values.amount !== state.values.amount;

    setHasDifference(hasDifference);
  };

  const form = useForm({
    values,
    onUpdate: state => {
      form.showErrors();
      setFormState(state);
      _checkDifference(state);
    },
  });

  const [formState, setFormState] = useState(form.getState());

  useEffect(() => {
    Promise.resolve()
      .then(() => DoctorProvider.getConfigs())
      .then(result => {
        if (!result.ok) {
          _navigateToError();
          return;
        }

        const configs = result.formConfigs;

        for (let i = 0; i < configs.length; i++) {
          const config = configs[i];

          if (config.code === editForm.doctor.formCode) {
            setFormConfig(config);
            break;
          }
        }

        setIsSettingUp(false);
      });
  }, [null]);

  const _createNewForm = async ({ code, practiceReference, email, contact, appointment, amount }) => {
    const result = await FormProvider.createForm(practiceReference, email, contact, appointment, amount, code);

    if (!result.ok) _navigateToError();

    return result.ok;
  };

  const _submit = async () => {
    setIsLoading(true);

    const uid = editForm.uid;

    const code = formConfig.code;
    const practiceReference = formState.values.practiceReference;
    const email = formState.values.email;
    const contact = formState.values.contact;
    const appointment = formState.values.appointment;
    const amount = Number(
      /[\.,]/.test(formState.values.amount)
        ? formState.values.amount.replace(/[\.,]/, '')
        : formState.values.amount + '00'
    );

    const newFormCreated = await _createNewForm({ code, practiceReference, email, contact, appointment, amount });

    if (newFormCreated) {
      await cancelForm(uid);
      refreshForms();
    }

    setIsLoading(false);
    cancelEditing();
  };

  const _validatePracticeReference = value =>
    !value || value.length <= 50 || 'Practice reference has a maximum character limit of 50.';

  const _validateEmail = value =>
    /[A-Z]/g.test(value || '')
      ? 'Lower case required.'
      : is.email((value || '').toLowerCase()) || 'This must be a valid email address.';

  const _validateContact = value => {
    if (!value || (!value.code && !value.number)) return true;

    const { code, number } = value;

    return !code && number
      ? 'A country code is required.'
      : code && !number
      ? 'A number is required.'
      : !/^\d{1,7}$/.test(code) || !/^\d{4,12}$/.test(number)
      ? 'This must be a valid phone number.'
      : true;
  };

  const _validateAppointment = value => {
    const time = new Date(value).getTime();

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    return is.nan(time) ? 'This must be a valid date.' : time >= today.getTime() || 'The date must be in the future.';
  };

  const _validateAmount = value => {
    const required = formConfig.payment.required;

    if (!required && !value) return true;

    if (value && !/^(\d*|\d*[\.,]\d{2})$/.test(value))
      return 'This value must be in a valid format (example: 3000.00 or 3000).';

    const valueCents = Number(/[\.,]/.test(value) ? value.replace(/[\.,]/, '') : value + '00');
    const exact = formConfig.payment.amount.exact;

    if (exact) {
      const cents = String(exact);

      let rands = cents.replace(/^(\d*?)(\d{0,2})$/, '$1.$2');
      rands = rands.startsWith('.') ? '0' + rands : rands;

      return valueCents === exact || `The amount needs to be exactly R ${rands}`;
    }

    const min = formConfig.payment.amount.min;
    const max = formConfig.payment.amount.max;

    if (min && valueCents < min) {
      const cents = String(min);

      let rands = cents.replace(/^(\d*?)(\d{0,2})$/, '$1.$2');
      rands = rands.startsWith('.') ? '0' + rands : rands;

      return `The minimum charge amount is R ${rands}`;
    }

    if (max && valueCents > max) {
      const cents = String(max);

      let rands = cents.replace(/^(\d*?)(\d{0,2})$/, '$1.$2');
      rands = rands.startsWith('.') ? '0' + rands : rands;

      return `The maximum charge amount is R ${rands}`;
    }

    return true;
  };

  const Field = form.Field;

  const hasValues = Object.keys(formState.values || {}).length;

  return (
    <div className='form-edit'>
      <div className='form-edit-background'>
        {isSettingUp ? (
          <div className='form-edit-loading'>
            <Spinner size='2em' />
          </div>
        ) : (
          <div className='form-edit-modal'>
            <div className='form-edit-modal__label'>{formConfig.label}</div>
            <Field
              type='text'
              name='practiceReference'
              label='Reference (optional)'
              validate={_validatePracticeReference}
            />

            <Field type='text' name='email' label='Email address (lower case)' validate={_validateEmail} />

            <Field type='contact' name='contact' label='Phone number (optional)' validate={_validateContact} />

            <Field type='date' name='appointment' label='Appointment Date' validate={_validateAppointment} />

            {!(formConfig.payment && formConfig.payment.enabled) || (
              <Field type='text' name='amount' label='Charge Amount (R)' validate={_validateAmount} />
            )}

            <div className='form-edit-modal__warning'>
              This will cancel the existing form and re-create a new form with the updated details. A new email
              notification will be sent to the patient.
            </div>

            <div className='form-edit-modal__actions'>
              <div className='form-edit-modal__cancel' onClick={cancelEditing}>
                Cancel
              </div>
              <div className='form-edit-modal__action'>
                <Button
                  label='Save'
                  onPress={_submit}
                  disabled={!hasValues || !formState.valid || !hasDifference}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const scope = '.form-edit';
const styles = `
{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #55555533;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.form-edit-background {
  width: 550px;
  height: 90%;
  max-height: 750px;
  background: conf(color, white);
  padding: 20px 70px;
  border-radius: 20px;
  overflow: auto;
}

.form-edit-modal__warning {
  color: conf(color, warn);
  font-preset: 1em light;
}

.form-edit-loading {
  padding-top: 60px;
}

.form-edit-modal__label {
  display: flex;
  align-items: center;
  height: 80px;
  color: conf(color, primary);
  font-preset: 1.2em light;
  user-select: none;
}

.form-edit-modal__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.form-edit-modal__action {
  min-width: 90px;
}

.form-edit-modal__cancel {
  padding: conf(spacing, smaller) conf(spacing, small);
  margin-right: conf(spacing, small);
  background: conf(color, warn);
  color: conf(color, white);
  font-preset: normal;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}

.form-edit-modal__cancel:hover {
  background: conf(color, warn);
  outline: 1px solid conf(color, white);
  outline-offset: -2px;
}

.form-edit-modal__cancel:active {
  background: conf(color, warn);
  outline: 2px solid conf(color, white);
  outline-offset: -3px;
}
`;

CustomStyleSheet.create(styles, scope);
