import { useEffect, useState } from 'react';

import { FullScreenLoader } from './core/components';

import AppRouter from './navigation/app-router';

import { AuthenticationProvider } from './providers/AuthenticationProvider';
import { navigator } from './navigation/app-router';

export function AppRoot() {
  const [isSettingUp, setIsSettingUp] = useState(true);

  useEffect(() => {
    AuthenticationProvider.onReady(() => {
      setIsSettingUp(false);

      AuthenticationProvider.onLogout(() => {
        navigator.navigate('/login');
      });
    });
  });

  return isSettingUp ? <FullScreenLoader /> : <AppRouter />;
}

export default AppRoot;
