import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';
import flexbox from '@prahatech/util-react-web/stylesheet/common/properties/flexbox';

flexbox(CustomStyleSheet);

CustomStyleSheet.defineGlobalProperty('font-preset', (size = 'inherit', preset) => {
  if (!preset) {
    preset = size || 'normal';
    size = 'inherit';
  }

  switch (preset.toLowerCase()) {
    case 'thin':
    case 'light':
      return `
        font-size: ${size};
        font-family: Ubuntu, sans-serif;
        font-weight: 300;
        text-decoration: none;
      `;

    case 'normal':
      return `
        font-size: ${size};
        font-family: Ubuntu, sans-serif;
        font-weight: 400;
        text-decoration: none;
      `;

    case 'bold':
    case 'strong':
      return `
        font-size: ${size};
        font-family: Ubuntu, sans-serif;
        font-weight: bold;
        text-decoration: none;
      `;
  }
});
