import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

import { Button } from '../../core/components';

import { navigator } from '../../navigation/app-router';

const _navigateBack = () => navigator.back();

export function KaboomKapowScreen() {
  return (
    <div className='kaboom-kapow-screen'>
      <h1>Something has gone wrong.</h1>

      <p>
        Please check your internet connection and click the retry button. <br />
        Contact us on support@docpay.co.za if the problem persists.
      </p>

      <div className='retry-container'>
        <Button label='Retry' onPress={_navigateBack} />
      </div>
    </div>
  );
}

const scope = '.kaboom-kapow-screen';
const styles = `
  {
    flexbox: column center center;
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  h1 {
    color: conf(color, warn);
    margin: 0;
    font-preset: 1.6em light;
    text-align: center;
    padding-bottom: conf(spacing, smallest);
  }

  p {
    color: conf(color, darkerGrey);
    font-preset: 1em light;
    text-align: center;
    margin: 0;
  }

  .retry-container {
    padding-top: .5em;
    width: 180px;
  }
`;

CustomStyleSheet.create(styles, scope);
