import React from 'react';
import ReactDOM from 'react-dom';
import AppRoot from './app-root';
import reportWebVitals from './reportWebVitals';
import Field from '@prahatech/util-react-web/form/field';

import './core/stylesheet';

import { TextInput, DateInput, DateTimeInput, RadioInput, BooleanQuestionInput, ContactInput } from './core/components';

const $root = document.getElementById('app-root');

Field.types = {
  'date': DateInput,
  'datetime': DateTimeInput,
  'radio': RadioInput,
  'question': BooleanQuestionInput,
  'text': TextInput,
  'contact': ContactInput,
};

const $react = (
  <React.StrictMode>
    <AppRoot />
  </React.StrictMode>
);

ReactDOM.render($react, $root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
