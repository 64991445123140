import { useState, useEffect } from 'react';
import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

import { DoctorProvider } from '../../providers';
import { navigator } from '../../navigation/app-router';

import { FullScreenLoader } from '../../core/components';

import { Editor } from './editor';
import { Tooltip } from '../../core/components/tooltip';

const _navigateToError = () => navigator.navigate(`/feedback/kaboom-kapow`);
const _navigateToForms = () => navigator.navigate('/forms');

export function NotesScreen(props) {
  const [notes, setNotes] = useState('');
  const [isSettingUp, setIsSettingUp] = useState(true);

  const pfUid = props.params.pf_uid;

  const _getNotes = async () => {
    const result = await DoctorProvider.getNotes(pfUid);

    if (result.ok) {
      setNotes(result.notes ? result.notes.content : '');
      return;
    }
  };

  useEffect(() => {
    Promise.resolve()
      .then(_getNotes)
      .then(() => {
        setIsSettingUp(false);
      });
  }, []);

  const _editorToHtml = editedNotes => {
    setNotes(editedNotes);
  };

  const _saveNotes = async () => {
    const response = await DoctorProvider.putNotes(pfUid, notes);

    if (!response.ok) {
      _navigateToError();

      return;
    }

    _navigateToForms();
  };

  return (
    <div className='notes-screen'>
      {isSettingUp ? (
        <FullScreenLoader />
      ) : (
        <div className='notes-screen__editor'>
          <Editor notes={notes} htmlText={_editorToHtml} />
          <Tooltip
            text='Cancel'
            onClick={_navigateToForms}
            className='notes-screen__editor__btn notes-screen__editor__cancel'>
            <svg className='notes-screen__editor__icon' viewBox='0 0 270 270' xmlns='http://www.w3.org/2000/svg'>
              <path d='M270 21.24L248.76 0L135 113.82L21.18 0L0 21.24L113.76 135L0 248.82L21.18 270L135 156.24L248.76 270L270 248.82L156.18 135L270 21.24Z' />
            </svg>
          </Tooltip>

          <Tooltip text='Save' onClick={_saveNotes} className='notes-screen__editor__btn notes-screen__editor__save'>
            <svg className='notes-screen__editor__icon' viewBox='0 0 262 268' xmlns='http://www.w3.org/2000/svg'>
              <path d='M241.67 267.773H20.2441C9.08203 267.773 0 258.691 0 247.529V20.3027C0 9.11133 9.11133 0 20.3027 0H87.0117V85.2539H174.902V0H211.318L261.914 52.1191V247.529C261.914 258.691 252.832 267.773 241.67 267.773ZM20.3027 17.5781C18.8379 17.5781 17.5781 18.8086 17.5781 20.3027V247.529C17.5781 248.994 18.8086 250.195 20.2441 250.195H241.641C243.105 250.195 244.307 248.965 244.307 247.529V59.2383L203.906 17.5781H192.48V85.6934C192.48 95.1562 184.805 102.832 175.342 102.832H86.5723C77.1094 102.832 69.4336 95.1562 69.4336 85.6934V17.5781H20.3027Z' />
              <path d='M182.227 0H77.0508V17.5781H182.227V0Z' />
              <path d='M130.928 222.686C106.26 222.686 86.1914 202.617 86.1914 177.949C86.1914 153.281 106.26 133.213 130.928 133.213C155.596 133.213 175.664 153.281 175.664 177.949C175.664 202.617 155.596 222.686 130.928 222.686ZM130.928 150.791C115.957 150.791 103.77 162.979 103.77 177.949C103.77 192.92 115.957 205.107 130.928 205.107C145.898 205.107 158.086 192.92 158.086 177.949C158.086 162.979 145.898 150.791 130.928 150.791Z' />
            </svg>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

const scope = '.notes-screen';
const styles = `
  {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .notes-screen__editor {
    height: 100%;
    padding: 60px;
  }

  .notes-screen__editor__btn {
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    box-shadow: 2px 2px 6px conf(color, grey);
    cursor: pointer;
  }

  .notes-screen__editor__btn:hover {
    outline: 3px solid conf(color, white);
    outline-offset: -6px;
  }

  .notes-screen__editor__btn:active {
    transform: translateY(2px);
  }

  .notes-screen__editor__icon {
    width: 20px;
    height: 20px;
    fill: conf(color, white);
  }

  .notes-screen__editor__save {
    position: fixed;
    right: 60px;
    bottom: 30px;
    background-color: conf(color, primary);
  }

  .notes-screen__editor__cancel {
    position: fixed;
    right: 140px;
    bottom: 30px;
    background-color: conf(color, grey);
  }
`;

CustomStyleSheet.create(styles, scope);
