import { useState, useEffect, useRef } from 'react';

import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

const genName = () => Date.now() + '-+-' + Math.random() * 1000000;

export function ContactInput(props) {
  const { onFieldChange, onFieldFocus, onFieldBlur, key, label, value, required, error, showErrors } = props;

  const [hasBeenTouched, setHasBeenTouched] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [focusInput, setFocusInput] = useState(undefined);

  const [code, setCode] = useState(value ? value.code : undefined);
  const [number, setNumber] = useState(value ? value.number : undefined);

  const [uniqueFieldName, setUniqueFieldName] = useState(genName());

  useEffect(() => {
    setUniqueFieldName(genName());
  }, [key]);

  const _changeCode = e => {
    setFocusInput(e.target);
    setCursorPosition(e.target.selectionStart);
    const code = e.target.value;

    setCode(code);
    _changeValue(e, { code, number });
  };

  const _changeNumber = e => {
    setFocusInput(e.target);
    setCursorPosition(e.target.selectionStart);
    const number = e.target.value;

    setNumber(number);
    _changeValue(e, { code, number });
  };

  const _changeValue = (e, { code, number }) => {
    onFieldChange({ code, number });
    typeof props.onChange === 'function' && props.onChange(e);
  };

  const _focus = e => {
    onFieldFocus();

    typeof props.onFocus === 'function' && props.onFocus(e);
  };

  const _blur = e => {
    onFieldBlur();

    typeof props.onBlur === 'function' && props.onBlur(e);

    value && setHasBeenTouched(true);
  };

  useEffect(() => {
    setUniqueFieldName(genName());
  }, [key]);

  useEffect(() => {
    if (!focusInput) return;

    focusInput.selectionStart = cursorPosition;
    focusInput.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  const shouldShowErrors = showErrors && hasBeenTouched && (value || required) && error;

  return (
    <div className='core-contact-input'>
      <div className='core-contact-input__label'>
        {label}
        {required ? <span className='core-contact-input__label__required'>*</span> : ''}
      </div>
      <div className='contact-inputs'>
        <span className='plus'>+</span>

        <input
          type='text'
          name={uniqueFieldName + '-code'}
          autoComplete='new-password'
          className='input_code'
          placeholder={'Code'}
          value={code || ''}
          onChange={_changeCode}
          onFocus={_focus}
          onBlur={_blur}
        />

        <input
          type='text'
          name={uniqueFieldName + '-number'}
          autoComplete='new-password'
          className='input_number'
          placeholder='Number (no leading zero)'
          value={number || ''}
          onChange={_changeNumber}
          onFocus={_focus}
          onBlur={_blur}
        />
      </div>
      <div className='core-contact-input__error'>{shouldShowErrors && <span>{error}</span>}</div>
    </div>
  );
}

const scope = '.core-contact-input';
const styles = `
  {
    position: relative;
    width: 100%;
    font-preset: light;
    margin: 0 0 conf(spacing, small) 0;
  }

  .contact-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .plus {
    position: absolute;
    padding: 0.7em 0.3em 1em 0.1em;
    color: conf(color, primary);
    text-align: center;
  }

  .core-contact-input__label {
    font-preset: 1em light;
    color: conf(color, primary);
  }

  .core-contact-input__label__required {
    color: conf(color, warn);
    padding: 0 2px;
  }

  .core-contact-input__error {
    padding-top: 4px;
    font-preset: .8em light;
    color: conf(color, warn);
  }

  label span {
    color: conf(color, warn);
    padding: 0 2px;
  }

  input {
    padding: 1em 0 conf(spacing, smaller) 0;
    border: none;
    outline: none;
    width: 100%;
    color: conf(color, darkerGrey);
    border-bottom: 2px solid conf(color, lightGrey);
  }

  .input_code {
    width: 55px;
    padding-left: 15px;
    margin-right: 10px;
    text-align: center;
  }

  .input_number {
    width: 100%;
  }

  input:focus {
    border-bottom: 2px solid conf(color, primary);
  }

  input::placeholder {
    color: conf(color, grey);
  }

  > span {
    font-preset: .8em light;
    color: conf(color, warn);
  }
`;

CustomStyleSheet.create(styles, scope);
