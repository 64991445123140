const _second = 1000;
const _minute = 60 * _second;
const _hour = 60 * _minute;
const _day = 24 * _hour;
const _week = 7 * _day;
const _month = 30 * _day;
const _year = 365 * _day;

const brackets = {
  'seconds': 0,
  'minute': 1,
  'hour': 2,
  'day': 3,
  'week': 4,
  'month': 5,
  'year': 6,
};

const tense = (string, direction) => (direction === 1 ? `in ${string}` : `${string} ago`);

const stringify = (bracket, amount, direction) =>
  [
    amount => (!amount ? 'now' : tense(`${amount} second${amount >= 2 ? 's' : ''}`, direction)),
    amount => (!amount ? 'now' : tense(`${amount} minute${amount >= 2 ? 's' : ''}`, direction)),
    amount => (!amount ? tense('1 hour', direction) : tense(`${amount} hour${amount >= 2 ? 's' : ''}`, direction)),
    amount =>
      !amount
        ? 'today'
        : amount === 1 && direction === 1
        ? 'tomorrow'
        : amount === 1 && direction === -1
        ? 'yesterday'
        : tense(`${amount} day${amount >= 2 ? 's' : ''}`, direction),
    amount =>
      !amount
        ? 'this week'
        : amount === 1 && direction === 1
        ? 'next week'
        : amount === 1 && direction === -1
        ? 'last week'
        : tense(`${amount} week${amount >= 2 ? 's' : ''}`, direction),
    amount =>
      !amount
        ? 'this month'
        : amount === 1 && direction === 1
        ? 'next month'
        : amount === 1 && direction === -1
        ? 'last month'
        : tense(`${amount} month${amount >= 2 ? 's' : ''}`, direction),
    amount =>
      !amount
        ? 'this year'
        : amount === 1 && direction === 1
        ? 'next year'
        : amount === 1 && direction === -1
        ? 'last year'
        : tense(`${amount} year${amount >= 2 ? 's' : ''}`, direction),
  ][bracket](amount);

function cloneDate(date) {
  return new Date(date);
}

function clearTime(date) {
  const d = cloneDate(date);

  d.setHours(0, 0, 0, 0);

  return d;
}

export function humanize(
  _from,
  _to,
  { round = true, includeTime = false, minBracket = brackets.day, maxBracket = Number.POSITIVE_INFINITY } = {}
) {
  _from = includeTime ? cloneDate(_from) : clearTime(_from);
  _to = includeTime ? cloneDate(_to) : clearTime(_to);

  const [direction, first, second] = _from < _to ? [1, _from, _to] : [-1, _to, _from];

  const difference = Math.floor(second - first);

  const years = difference / _year;
  const months = difference / _month;
  const weeks = difference / _week;
  const days = difference / _day;
  const hours = difference / _hour;
  const minutes = difference / _minute;
  const seconds = difference / _second;

  let bracket =
    years > 1
      ? brackets['year']
      : months >= 1
      ? brackets['month']
      : weeks >= 1
      ? brackets['week']
      : days >= 1
      ? brackets['day']
      : hours >= 1
      ? brackets['hour']
      : minutes >= 1
      ? brackets['minute']
      : seconds >= 1
      ? brackets['seconds']
      : minBracket;

  bracket = Math.min(Math.max(bracket, minBracket), maxBracket);

  let amount = [seconds, minutes, hours, days, weeks, months, years][bracket];

  if (round) amount = Math.round(amount);

  const humanized = stringify(bracket, amount, direction);

  return humanized;
}

humanize.brackets = brackets;
