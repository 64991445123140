import request from '@prahatech/util-react-web/http/request';
import hash from '@prahatech/util-react-web/utils/hash';

import { AuthenticationProvider } from './AuthenticationProvider';

const ms_doctors_url = '/drs-ms-doctors';

const messages = {
  generalFailure: 'This service is currently unavailable. If this persists, please contact an administrator.',
  configFailure: 'A fatal error has occurred when attempting to load the application. Please contact an administrator.',
  unauthorized: 'User authentication could not be verified. Please sign in.',
};

export class DoctorProvider {
  static async getForms(query = { orderBy: 'uid', orderDirection: 'desc' }) {
    const result = {
      ok: false,
      errors: [],
      status: 400,
      forms: undefined,
    };

    const { token } = await AuthenticationProvider.getToken();

    const response = await request({
      method: 'GET',
      url: `${ms_doctors_url}/api/v1/doctors/by-authentication/forms`,
      query,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : {};

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, data.errors];

      return result;
    }

    result.ok = true;
    result.forms = data.forms;

    for (let i = 0; i < result.forms.length; i++) {
      const form = result.forms[i];

      form.hash = hash(JSON.stringify(form));
    }

    return result;
  }

  static async getConfigs() {
    const result = {
      ok: false,
      errors: [],
      status: 400,
      formConfigs: undefined,
    };

    const { token } = await AuthenticationProvider.getToken();

    const response = await request({
      method: 'GET',
      url: `${ms_doctors_url}/api/v1/doctors/by-authentication/configurations`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : {};

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, data.errors];

      return result;
    }

    result.ok = true;
    result.formConfigs = data.formConfigs;

    for (let i = 0; i < result.formConfigs.length; i++) {
      const config = result.formConfigs[i];

      config.hash = hash(JSON.stringify(config));
    }

    return result;
  }

  static async getNotes(pf_uid) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
      notes: undefined,
    };

    const { token } = await AuthenticationProvider.getToken();

    const response = await request({
      method: 'GET',
      url: `${ms_doctors_url}/api/v1/doctors/by-authentication/patient-notes/:pf_uid`,
      path: { pf_uid },
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    result.status = response.status;

    if (result.status === 404) {
      result.ok = true;

      return result;
    }

    const data = response.body ? JSON.parse(response.body) : {};

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, data.errors];

      return result;
    }

    result.ok = true;
    result.notes = data.notes;

    return result;
  }

  static async putNotes(pf_uid, notes) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
    };

    const { token } = await AuthenticationProvider.getToken();

    const response = await request({
      method: 'PUT',
      url: `${ms_doctors_url}/api/v1/doctors/by-authentication/patient-notes/:pf_uid`,
      path: { pf_uid },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        notes: notes,
      }),
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : {};

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, data.errors];

      return result;
    }

    result.ok = true;

    return result;
  }
}
