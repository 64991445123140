import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

import { Spinner } from './spinner';

export function Button(props) {
  const { secondary, onPress, label, disabled, loading } = props;

  const _press = e => {
    if (disabled) return;

    typeof onPress === 'function' && onPress(e);
  };

  return (
    <div className='core-button'>
      {loading ? (
        <button className={['core-button__btn', secondary ? 'core-button__btn--secondary' : undefined].join(' ')}>
          <Spinner size='1.1em' color={secondary ? 'primary' : 'light'} />
        </button>
      ) : (
        <button
          className={['core-button__btn', secondary ? 'core-button__btn--secondary' : undefined].join(' ')}
          onClick={_press}
          disabled={disabled}>
          {label || 'Unlabeled'}
        </button>
      )}
    </div>
  );
}

const scope = '.core-button';
const styles = `
  {
    position: relative;
    width: 100%;
    margin: conf(spacing, small) 0;
    cursor: pointer;
  }

  .core-button__btn {
    width: 100%;
    font-preset: normal;
    padding: conf(spacing, smaller) conf(spacing, small);
    background: conf(color, primary);
    color: conf(color, white);
    outline: none;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    box-shadow:
      inset 0px 0px 0px 1px conf(color, primary),
      inset 0px 0px 0px 2px conf(color, white);
    transition: all .2s ease;
  }

  .core-button__btn--secondary {
    background: conf(color, transparent);
    color: conf(color, primary);
  }

  .core-button__btn:hover, .core-button__btn:focus {
    box-shadow:
      inset 0px 0px 0px 1px conf(color, primary),
      inset 0px 0px 0px 3px conf(color, white);
  }

  .core-button__btn--secondary:hover, .core-button__btn--secondary:focus {
    box-shadow: inset 0px 0px 0px 2px conf(color, primary);
  }

  .core-button__btn:disabled, .core-button__btn[disabled] {
    pointer-events: none;
    background: conf(color, lightGrey);
    box-shadow:
      inset 0px 0px 0px 1px conf(color, lightGrey),
      inset 0px 0px 0px 2px conf(color, white);
  }

  .core-button__btn--secondary:disabled, .core-button__btn--secondary[disabled] {
    pointer-events: none;
    background: conf(color, transparent);
    color: conf(color, lightGrey);
    box-shadow:
      inset 0px 0px 0px 1px conf(color, lightGrey),
      inset 0px 0px 0px 2px conf(color, white);
  }
`;

CustomStyleSheet.create(styles, scope);
