import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

export function Tooltip(props) {
  const { text, onClick, className } = props;

  return (
    <div className='tooltip'>
      <div className={`tooltip-box ${className}`} onClick={onClick}>
        <span className='tooltip-text'>{text}</span>
        {props.children}
      </div>
    </div>
  );
}

const scope = '.tooltip';
const styles = `
  .tooltip-box {
    position: relative;
    display: inline-block;
  }

  .tooltip-text {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 100%;
    width: 100px;
    margin: 10px -50px;
    padding: 2px 0;
    border-radius: 20px;
    text-align: center;
    color: #fff;
    background-color: conf(color, darkerGrey);
    user-select: none; 
  }

  .tooltip-text::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: conf(color, darkerGrey) transparent transparent transparent;
  }

  .tooltip-box:hover .tooltip-text {
    display: block;
  }
`;

CustomStyleSheet.create(styles, scope);
