import { useState, useEffect } from 'react';
import routes from './routes';

import Navigator from '@prahatech/util-react-web/navigation/Navigator';

export const navigator = new Navigator(routes);

export function AppRouter() {
  const [currentRoute, setCurrentRoute] = useState(navigator.currentRoute);

  useEffect(() => {
    const _update = newRoute => setCurrentRoute(newRoute);

    navigator.onUpdate(_update);

    return () => navigator.offUpdate(_update);
  });

  const Screen = currentRoute.route.screen;
  const navigationProps = currentRoute.route.props;
  const params = currentRoute.params;

  return <Screen navigationProps={navigationProps} params={params} />;
}

export default AppRouter;
