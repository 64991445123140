import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';
import { useState } from 'react';

export function BooleanQuestionInput(props) {
  const { onFieldChange, onFieldFocus, onFieldBlur, value, required, error, showErrors, label } = props;

  const [hasBeenTouched, setHasBeenTouched] = useState(!!value);

  const _setTrue = e => {
    onFieldChange('Yes');
    typeof props.onChange === 'function' && props.onChange(e);
  };

  const _setFalse = e => {
    onFieldChange('No');
    typeof props.onChange === 'function' && props.onChange(e);
  };

  const _focus = e => {
    onFieldFocus();
    typeof props.onFocus === 'function' && props.onFocus(e);
  };

  const _blur = e => {
    onFieldBlur();

    typeof props.onBlur === 'function' && props.onBlur(e);

    value && setHasBeenTouched(true);
  };

  const shouldShowErrors = showErrors && hasBeenTouched && (value || required) && error;

  const isYes = value === 'Yes';
  const isNo = value === 'No';

  return (
    <div className='boolean-question-input'>
      <div className='bqi__question'>
        <div className='bqi__label'>
          {label}
          {required ? <span className='bqi__label__required'>*</span> : ''}
        </div>
        <div className='bqi__error'>{shouldShowErrors && <span>{error}</span>}</div>
      </div>
      <div className='bqi__radio-list'>
        <div className='bqi__radio' onClick={_setTrue} onFocus={_focus} onBlur={_blur}>
          <div className={['bqi__radio__btn', isYes && 'bqi__radio__btn--active'].join(' ')}>
            <div className='bqi__radio__btn__border'></div>
          </div>
          <div className='bqi__radio__label'>Yes</div>
        </div>
        <div className='bqi__radio' onClick={_setFalse} onFocus={_focus} onBlur={_blur}>
          <div className={['bqi__radio__btn', isNo && 'bqi__radio__btn--active'].join(' ')}>
            <div className='bqi__radio__btn__border'></div>
          </div>
          <div className='bqi__radio__label'>No</div>
        </div>
      </div>
    </div>
  );
}

const scope = '.boolean-question-input';
const styles = `
  {
    width: 100%;
    margin: 0 0 conf(spacing, small) 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .bqi__label {
    color: conf(color, primary);
    font-preset: 1em light;
  }

  .bqi__label__required {
    color: conf(color, warn);
    padding: 0 2px;
  }

  .bqi__error {
    font-preset: .8em light;
    color: conf(color, warn);
  }

  .bqi__radio-list {
    display: flex;
    flex-direction: row;
  }

  .bqi__radio {
    height: 22px;
    padding-top: 3px;
    padding-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bqi__radio__btn {
    width: 22px;
    height: 22px;
    background-color: conf(color, lightGrey);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bqi__radio__btn--active{
    background-color: conf(color, primary);
  }

  .bqi__radio__btn__border {
    width: 14px;
    height: 14px;
    border: 1px solid white;
    border-radius: 50%;
  }

  .bqi__radio__label {
    padding-left: 5px;
    font-size: 14px;
    color: conf(color, grey);
  }
`;

CustomStyleSheet.create(styles, scope);

export default BooleanQuestionInput;
