import request from '@prahatech/util-react-web/http/request';

import { AuthenticationProvider } from './AuthenticationProvider';

const ms_forms_url = '/drs-ms-forms';

const messages = {
  generalFailure: 'This service is currently unavailable. If this persists, please contact an administrator.',
  configFailure: 'A fatal error has occurred when attempting to load the application. Please contact an administrator.',
  unauthorized: 'User authentication could not be verified. Please sign in.',
};

export class FormProvider {
  static async createForm(practiceReference, patientEmail, contact, appointmentDate, amount, formCode) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
      form: undefined,
    };

    const { token } = await AuthenticationProvider.getToken();

    const response = await request({
      method: 'POST',
      url: `${ms_forms_url}/api/v1/forms`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        practiceReference: practiceReference,
        email: patientEmail,
        contact: contact && contact.code && contact.number ? contact : undefined,
        appointment: appointmentDate,
        chargeCents: amount,
        formCode: formCode,
      }),
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, ...data.errors];

      return result;
    }

    result.ok = true;
    result.form = {
      uid: data.form.uid,
      reference: data.form.reference,
    };

    return result;
  }

  static async fetchFormDocument(uid) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
      url: undefined,
      blob: undefined,
    };

    const { token } = await AuthenticationProvider.getToken();

    const response = await request({
      method: 'GET',
      url: `${ms_forms_url}/api/v1/forms/by-uid/:uid/document`,
      path: { uid },
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, ...data.errors];

      return result;
    }

    result.ok = true;
    result.url = data.dataUrl + encodeURIComponent(data.base64);

    const mime = 'application/pdf';
    const bytes = window.atob(data.base64);

    const buffer = new ArrayBuffer(bytes.length);
    const uint8 = new Uint8Array(buffer);

    for (let i = 0; i < bytes.length; i++) uint8[i] = bytes.charCodeAt(i);

    const blob = new Blob([buffer], { type: mime });

    result.blob = blob;

    return result;
  }

  static async updateFormToProcessed(formUid) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
    };

    const { token } = await AuthenticationProvider.getToken();

    const response = await request({
      method: 'POST',
      url: `${ms_forms_url}/api/v1/forms/by-uid/:uid/status/processed`,
      path: { uid: formUid },
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, ...data.errors];

      return result;
    }

    result.ok = true;

    return result;
  }

  static async cancelForm(formUid) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
    };

    const { token } = await AuthenticationProvider.getToken();

    const response = await request({
      method: 'POST',
      url: `${ms_forms_url}/api/v1/forms/by-uid/:uid/status/cancelled`,
      path: { uid: formUid },
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, ...data.errors];

      return result;
    }

    result.ok = true;

    return result;
  }
}
