import { FormsScreen, CreateFormScreen } from '../screens/forms';
import { KaboomKapowScreen } from '../screens/feedback/kaboom-kapow';
import { LoginScreen } from '../screens/login/login';
import { NotesScreen } from '../screens/notes/notes';

export const routes = {
  '/': {
    screen: LoginScreen,
  },
  '/login': {
    screen: LoginScreen,
  },
  '/forms': {
    screen: FormsScreen,
  },
  '/forms/create': {
    screen: CreateFormScreen,
  },
  '/feedback/kaboom-kapow': {
    screen: KaboomKapowScreen,
  },
  '/notes/:pf_uid': {
    screen: NotesScreen,
  },
};

export default routes;
