import { useState, useEffect, useRef } from 'react';
import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';
import { DoctorProvider, FormProvider } from '../../providers';
import { navigator } from '../../navigation/app-router';
import { Button, FullScreenLoader, Spinner } from '../../core/components';
import Displayable from '@prahatech/util-react-web/utils/Displayable';
import useForm from '@prahatech/util-react-web/form/useForm';
import { TableRow } from './components/table-row';
import { EditForm } from './components/form-edit';

const _navigateToCreateForms = () => navigator.navigate('/forms/create');
const _navigateToError = () => navigator.navigate(`/feedback/kaboom-kapow`);

const getDocumentScrollableHeight = () => {
  const body = document.body;
  const html = document.documentElement;

  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );

  const scrollableHeight = height - window.innerHeight;

  return scrollableHeight;
};

const getScrollTop = $el => {
  return $el.scrollingElement ? $el.scrollingElement.scrollTop : $el.scrollTop;
};

const maxLoadAmount = 50;
const loadMoreThreshold = window.innerHeight;
let isCurrentLoadingMore = false;
let isFiltering = false;

const validateFilter = values => {
  const errors = {};

  if (values.practiceReference && values.practiceReference.length > 50)
    errors.practiceReference = 'Practice reference has a maximum character limit of 50.';

  if (values.email && values.email.length < 3) errors.email = 'We require at least 3 character to apply email filters.';

  if (values.contact && values.contact.length < 3)
    errors.contact = 'We require at least 3 numbers to apply contact number filters.';

  const usesDateFilter = values.dateField && values.dateField !== 'None';

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const nowTime = Date.now();
  const yesterdayTime = yesterday.getTime();

  const fromTime = new Date(values.from).getTime();
  const toTime = new Date(values.to).getTime();

  if (usesDateFilter && values.from) {
    errors.from = Number.isNaN(fromTime)
      ? 'We need a valid date.'
      : values.dateField === 'Created' && fromTime > nowTime
      ? 'This date must be in the past.'
      : values.dateField === 'Appointment' && fromTime < yesterdayTime
      ? "This date can't be in the past."
      : undefined;

    !errors.from && delete errors.from;
  }

  if (usesDateFilter && values.to) {
    errors.to = Number.isNaN(toTime)
      ? 'We need a valid date.'
      : fromTime > toTime
      ? 'This date can\'t be before the "From" date.'
      : undefined;

    !errors.to && delete errors.to;
  }

  return Object.keys(errors).length ? errors : undefined;
};

export function FormsScreen() {
  const filterForm = useForm({
    values: { includeExpired: 'No', showPaidOnly: 'No', dateField: 'None' },
    onUpdate: state => {
      setFilterState(state);
      filterForm.showErrors();
    },
  });

  filterForm._.globalValidate = validateFilter;

  const $scrollableArea = useRef(null);

  const [isSettingUp, setIsSettingUp] = useState(true);

  const [showFilter, setShowFilter] = useState(false);
  const [filterState, setFilterState] = useState(filterForm.getState());

  const [forms, setForms] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMoreOffset, setLoadMoreOffset] = useState(0);
  const [hasLoadedMax, setHasLoadedMax] = useState(false);

  const [editForm, setEditForm] = useState(undefined);

  const [downloadingDocument, setDownloadingDocument] = useState(undefined);
  const [markingProcessed, setMarkingProcessed] = useState(undefined);

  const [__, __forceRerender] = useState(0);
  const _forceRerender = () => __forceRerender(__ + 1);

  const _toggleFilterPane = (bool = !showFilter) => {
    setShowFilter(bool);
  };

  const _clearFilters = () => {
    filterForm.setValues({
      practiceReference: '',
      email: '',
      contact: '',
      includeExpired: 'No',
      showPaidOnly: 'No',
      dateField: 'None',
      from: '',
      to: '',
    });
    isFiltering = false;
    _refreshCurrentForms();
  };

  const _performSearch = () => {
    isFiltering = !!(
      filterState.values.practiceReference ||
      filterState.values.email ||
      filterState.values.contact ||
      filterState.values.includeExpired ||
      filterState.values.showPaidOnly ||
      filterState.values.dateField !== 'None'
    );
    _toggleFilterPane();
    _refreshCurrentForms();
  };

  const _openCompletedFormDocument = async uid => {
    if (downloadingDocument !== undefined) return;

    setDownloadingDocument(uid);

    const result = await FormProvider.fetchFormDocument(uid);

    if (!result.ok) {
      console.error(result.errors);

      setDownloadingDocument(undefined);

      return;
    }

    const blob = result.blob;
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');

    setDownloadingDocument(undefined);
  };

  const _markFormAsProcessed = async uid => {
    if (markingProcessed !== undefined) return;

    setMarkingProcessed(uid);

    const result = await FormProvider.updateFormToProcessed(uid);

    if (!result.ok) {
      console.error(result.errors);
    }

    setMarkingProcessed(undefined);

    return result.ok;
  };

  const _cancelForm = async uid => {
    const result = await FormProvider.cancelForm(uid);

    if (!result.ok) {
      console.error(result.errors);
    }

    const newForms = [];
    for (let form of forms) {
      if (form.uid === uid) continue;

      newForms.push(form);
    }

    setForms(newForms);
  };

  const _editForm = form => {
    setEditForm(form);
  };

  const _cancelEditForm = () => {
    setEditForm(undefined);
  };

  const _loadMoreForms = async (
    state = {
      forms,
      loadMoreOffset,
      hasLoadedMax,
    }
  ) => {
    const { forms, loadMoreOffset, hasLoadedMax } = state;

    if (isCurrentLoadingMore || hasLoadedMax) return;

    isCurrentLoadingMore = true;
    setIsLoadingMore(true);

    let filter = {
      orderBy: 'uid',
      orderDirection: 'desc',
      offset: loadMoreOffset,
      limit: maxLoadAmount,
    };

    if (isFiltering) {
      if (filterState.values.practiceReference) filter.practiceReference = filterState.values.practiceReference;

      if (filterState.values.email) filter.patientEmail = filterState.values.email;

      if (filterState.values.contact) filter.contact = filterState.values.contact;

      if (filterState.values.includeExpired) filter.includeExpired = filterState.values.includeExpired;

      if (filterState.values.showPaidOnly) filter.showPaidOnly = filterState.values.showPaidOnly;

      if (filterState.values.dateField !== 'None') {
        filter.dateField = filterState.values.dateField.toLowerCase();
        filter.from = Displayable.date(filterState.values.from).utc.format('yyyy-MM-dd') + 'T00:00:00.000Z';
        filter.to = Displayable.date(filterState.values.to).utc.format('yyyy-MM-dd') + 'T23:59:59.999Z';
      }
    }

    const response = await DoctorProvider.getForms(filter);

    if (!response.ok) {
      _navigateToError();

      return;
    }

    const newForms = [];
    let duplicates = 0;

    filterNewForms: for (let i = 0; i < (response.forms || []).length; i++) {
      const newForm = response.forms[i];

      for (let j = 0; j < forms.length; j++) {
        const existingForm = forms[j];

        if (existingForm.uid === newForm.uid) {
          duplicates++;
          continue filterNewForms;
        }
      }

      newForms.push(newForm);
    }

    if (!duplicates && newForms.length < maxLoadAmount) setHasLoadedMax(true);

    const mergedForms = [...forms, ...newForms];

    setLoadMoreOffset(mergedForms.length);
    setForms(mergedForms);

    setIsLoadingMore(false);
    isCurrentLoadingMore = false;
  };

  const _refreshCurrentForms = () => {
    if (isLoadingMore) return;

    const newState = {
      forms: [],
      loadMoreOffset: 0,
      hasLoadedMax: false,
    };

    setForms(newState.forms);
    setLoadMoreOffset(newState.loadMoreOffset);
    setHasLoadedMax(newState.hasLoadedMax);

    _loadMoreForms(newState);
  };

  useEffect(() => {
    Promise.resolve()
      .then(_loadMoreForms)
      .then(() => {
        setIsSettingUp(false);
      });
  }, []);

  useEffect(() => {
    const _onscroll = e => {
      const scrollTop = getScrollTop(window.document);
      const height = getDocumentScrollableHeight();

      if (height - scrollTop < loadMoreThreshold) _loadMoreForms();
    };

    window.addEventListener('scroll', _onscroll);

    return () => {
      window.removeEventListener('scroll', _onscroll);
    };
  });

  const _renderForms = (forms = []) => {
    let $result = [];

    if (!isLoadingMore && (!forms || !forms.length)) {
      $result.push(
        <div key='no-rows' className='form-data--empty'>
          {isFiltering
            ? 'We could not find any forms that met your filtering criteria.'
            : 'There are currently no active forms pending completion.'}
        </div>
      );
      return $result;
    }

    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      const uid = form.uid;

      const isDownloading = downloadingDocument === uid;
      const isMarkingProcessed = markingProcessed === uid;

      const _markProcessed = async () => {
        const ok = await _markFormAsProcessed(uid);

        if (ok) {
          form.status = 'PROCESSED';
          _forceRerender();
        }
      };

      $result.push(
        <TableRow
          key={form.hash}
          alternative={i % 2 === 0}
          form={form}
          isDownloading={isDownloading}
          isMarkingProcessed={isMarkingProcessed}
          markAsProcessed={_markProcessed}
          openCompletedFormDocument={_openCompletedFormDocument}
          cancelForm={_cancelForm}
          editForm={_editForm}
        />
      );
    }

    return $result;
  };

  const FilterField = filterForm.Field;

  document.title = 'docpay';

  return (
    <div ref={$scrollableArea} className='forms-screen'>
      {isSettingUp ? (
        <FullScreenLoader />
      ) : (
        <div className='forms-screen__wrap'>
          <div className='forms-screen__wrap__header'>
            <div className='logo'>
              <svg viewBox='0 0 231 43' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M164.062 9.32876C159.575 10.3186 156.119 13.4679 154.695 18.0401C154.695 18.0401 154.284 19.0767 154.273 20.1599C154.232 24.3983 154.314 31.1568 154.314 31.1568L154.273 43H157.386L157.393 35.8796C157.4 29.1806 157.413 28.7803 157.626 29.0989C158.947 31.074 161.473 32.8132 164.13 33.5754C165.287 33.9069 168.716 33.789 170.104 33.3688C180.402 30.2534 182.324 16.7947 173.301 10.9851C170.763 9.3521 167.009 8.67858 164.062 9.32876ZM191.16 9.37778C180.532 11.9936 178.114 25.778 187.226 31.807C192.429 35.2493 199.51 34.1217 202.7 29.3394L203.242 28.5281V33.4167H206.36L206.313 26.2975C206.313 26.2975 206.313 21.856 206.267 19.1782C206.256 18.4639 206.099 17.6164 206.099 17.6164C204.138 11.2863 197.404 7.84164 191.16 9.37778ZM209.767 9.59139C209.535 10.1949 209.817 24.4578 210.086 25.6578C211.227 30.7856 214.466 33.5229 219.699 33.782C222.689 33.9303 224.95 33.1459 226.809 31.3144C227.368 30.7635 227.857 30.3129 227.895 30.3129C228.369 30.3129 227.64 34.94 226.95 36.3069C224.475 41.2176 216.856 41.4055 214.118 36.6232L213.829 36.1189L212.11 36.1178C211.164 36.1166 210.39 36.1551 210.39 36.2018C210.39 36.4761 211.645 38.5725 212.245 39.3009C216.971 45.0334 226.392 43.7051 229.693 37.117C230.115 35.8458 230.958 35.624 230.958 21.675V9.46066L229.451 9.42214L227.943 9.38478L227.888 16.9803C227.826 25.3508 227.82 25.4103 226.949 27.1823C224.47 32.2191 216.242 32.135 213.827 27.048C212.937 25.171 212.919 24.9843 212.854 16.7818L212.796 9.39296H211.319C210.145 9.39296 209.826 9.43381 209.767 9.59139ZM168.918 12.2399C176.147 14.0586 178.459 23.2602 172.975 28.3857C170.117 31.0576 166.064 31.6401 162.43 29.902C156.15 26.8986 155.611 17.4262 161.496 13.5169C163.537 12.1629 166.55 11.6435 168.918 12.2399ZM196.258 12.2446C202.11 13.4831 205.174 20.7494 202.131 26.1702C198.384 32.8447 188.391 32.2681 185.431 25.2061C182.399 17.9654 188.541 10.6104 196.258 12.2446Z'
                  fill='#AFAEEA'
                />
                <path
                  d='M95.3743 0.394798C95.325 0.442885 95.2838 3.58565 95.2815 7.37758L95.2769 14.2722L94.9873 13.8749C87.6919 3.89936 71.4984 12.011 74.3286 24.2226C76.7352 34.6103 90.4867 37.0822 96.3566 28.1851C96.9966 27.2142 97.1889 26.8261 97.5565 25.6709C98.2182 23.5918 98.3281 20.8531 98.3281 11.6309V0.306641H96.8947C96.107 0.306641 95.4224 0.346712 95.3743 0.394798ZM110.422 9.6903C101.271 11.9435 97.9377 22.9781 104.347 29.804C111.491 37.4108 124.161 33.162 125.318 22.7709C126.23 14.5928 118.407 7.72449 110.422 9.6903ZM137.143 9.6903C126.718 12.256 124.347 25.7762 133.284 31.6897C138.894 35.4015 146.882 33.6429 150.219 27.9619L150.717 27.1135L149.056 27.0757L147.393 27.0391L146.711 27.7878C140.373 34.7271 128.964 28.6832 131 19.4655C132.608 12.1919 142.004 9.73953 146.831 15.3347L147.456 16.0594H149.033C150.827 16.0594 150.761 16.1384 150.012 14.8916C147.565 10.8169 141.887 8.52249 137.143 9.6903ZM88.625 12.5617C95.0709 14.2356 97.5416 22.3736 93.1967 27.6195C89.6566 31.8923 82.3601 31.7813 79.0731 27.4054C73.6737 20.2177 80.0359 10.3303 88.625 12.5617ZM115.422 12.5022C120.494 13.5761 123.623 19.4438 121.873 24.605C119.706 30.9959 111.606 32.8792 106.802 28.1061C100.165 21.5115 106.218 10.5536 115.422 12.5022Z'
                  fill='#595959'
                />
                <path
                  d='M30.6496 8.12456L29.0957 27.9858C31.4265 25.1762 32.4885 23.5886 35.8177 15.0255C39.1468 6.46245 42.7423 2.44312 48.4171 0.33358C39.7226 -0.655526 34.9394 0.18009 30.6496 8.12456Z'
                  fill='#AFAEEA'
                />
                <path
                  fillRule='evenodd'
                  d='M16.6945 33.5412C25.8916 33.5412 33.3474 26.111 33.3474 16.9453C33.3474 7.77956 25.8916 0.349335 16.6945 0.349335C7.49742 0.349335 0.0416565 7.77956 0.0416565 16.9453C0.0416565 26.111 7.49742 33.5412 16.6945 33.5412ZM16.6608 26.0006C21.6978 26.0006 25.781 21.9313 25.781 16.9116C25.781 11.8918 21.6978 7.82252 16.6608 7.82252C11.6238 7.82252 7.54052 11.8918 7.54052 16.9116C7.54052 21.9313 11.6238 26.0006 16.6608 26.0006Z'
                  fill='#AFAEEA'
                />
                <path
                  d='M41.2574 25.7667L42.8112 5.90549C40.4805 8.71513 39.4184 10.3027 36.0893 18.8657C32.7601 27.4288 29.1647 31.4482 23.4898 33.5576C32.1843 34.5467 36.9675 33.7112 41.2574 25.7667Z'
                  fill='#595959'
                />
                <path
                  fillRule='evenodd'
                  d='M55.1961 0.349335C45.999 0.349335 38.5432 7.77956 38.5432 16.9453C38.5432 26.1109 45.999 33.5412 55.1961 33.5412C64.3932 33.5412 71.849 26.1109 71.849 16.9453C71.849 7.77956 64.3932 0.349335 55.1961 0.349335ZM55.2299 7.88988C50.193 7.88988 46.1097 11.9592 46.1097 16.9789C46.1097 21.9987 50.193 26.0679 55.2299 26.0679C60.2669 26.0679 64.3501 21.9987 64.3501 16.9789C64.3501 11.9592 60.2669 7.88988 55.2299 7.88988Z'
                  fill='#595959'
                />
              </svg>
            </div>
            <div className={['actions', showFilter && 'actions--avoid-filter'].join(' ')}>
              <div className='action' onClick={_navigateToCreateForms}>
                <img className='action__icon' src='assets/icons/plus.svg' />
              </div>
              <div className='action' onClick={_refreshCurrentForms}>
                <img className='action__icon' src='assets/icons/refresh.svg' />
              </div>
              <div className={['action', showFilter && 'action--hidden'].join(' ')} onClick={() => _toggleFilterPane()}>
                <img className='action__icon' src='assets/icons/search.svg' />
              </div>
              <div
                style={{ marginLeft: '.5ch' }}
                className={['action', (showFilter || !isFiltering) && 'action--hidden'].join(' ')}
                onClick={_clearFilters}>
                <div className='action__text'>Clear Filters</div>
              </div>
            </div>

            <div className='forms-screen__wrap__header__form-table__form-labels' key={'form-labels'}>
              <div className='forms-screen__wrap__header__form-table__form-labels__dropdown'></div>
              <div className='forms-screen__wrap__header__form-table__form-labels__practice-reference'>Reference</div>
              <div className='forms-screen__wrap__header__form-table__form-labels__form'>Form Name</div>
              <div className='forms-screen__wrap__header__form-table__form-labels__appointment'>Appointment</div>
              <div className='forms-screen__wrap__header__form-table__form-labels__amount'>Amount</div>
              <div className='forms-screen__wrap__header__form-table__form-labels__status'>Status</div>
              <div className='forms-screen__wrap__header__form-table__form-labels__actions'></div>
            </div>
          </div>

          {_renderForms(forms)}

          {editForm && (
            <div className='form-edit'>
              <EditForm
                editForm={editForm}
                cancelEditing={_cancelEditForm}
                cancelForm={_cancelForm}
                refreshForms={_refreshCurrentForms}
              />
            </div>
          )}

          {isLoadingMore && (
            <div className='form-data--empty'>
              <Spinner size='2em' color='primary' />
            </div>
          )}

          <div
            className={['filter-pane__blind', showFilter && 'filter-pane__blind--shown'].join(' ')}
            onClick={() => _toggleFilterPane(false)}></div>

          <div className={['filter-pane', showFilter && 'filter-pane--shown'].join(' ')}>
            <div className='filter-pane__actions'>
              <div className='filter-pane__action' onClick={() => _toggleFilterPane()}>
                <img className='filter-pane__action-icon' src='assets/icons/search.svg' />
              </div>
              {isFiltering && (
                <div className='filter-pane__action' onClick={() => _clearFilters()}>
                  <div className='filter-pane__action-text'>Clear Filters</div>
                </div>
              )}
            </div>

            <div className='filter-pane__fields'>
              <FilterField type='text' name='practiceReference' label='Reference' />

              <FilterField type='text' name='email' label='Email Address' />

              <FilterField type='text' name='contact' label='Phone Number' />

              <FilterField type='radio' name='includeExpired' label='Include past forms' options={['Yes', 'No']} />

              <FilterField
                type='radio'
                name='showPaidOnly'
                label='Show completed payments only?'
                options={['Yes', 'No']}
              />

              <FilterField
                type='radio'
                name='dateField'
                label='Which date field do you want to filter by?'
                options={['None', 'Created', 'Appointment']}
              />

              {filterState.values &&
                filterState.values.dateField &&
                filterState.values.dateField !== 'None' && [
                  <FilterField key='from' type='date' name='from' label='From' />,
                  <FilterField key='to' type='date' name='to' label='To' />,
                ]}

              <Button label='Search' disabled={!filterState.valid} onPress={_performSearch} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const scope = '.forms-screen';
const styles = `
  {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .logo {
    height: 30px;
    width: 160px;
    position: fixed;
    top: 20px;
    left: 3em;
    z-index: 999;
  }

  .logo svg {
    width: 100%;
    height: 100%;
  }

  .forms-screen__wrap {
    width: 100%;
    min-width: 1050px;
    padding: 0 5ch 5ch 5ch;
  }

  .forms-screen__wrap__header {
    position: sticky;
    top: 0;
    background: conf(color, white);
    z-index: 50;
  }

  .actions {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1ch 0;
    transition: padding-right .2s ease;
  }

  .actions--avoid-filter {
    padding-right: 475px;
  }
  
  .action {
    padding: 1ch;
    margin-left: 1em;
    cursor: pointer;
  }

  .action--hidden {
    display: none;
  }

  .action__icon {
    width: 30px;
    height: 30px;
  }

  .action__text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 20px;
    margin: 0 .5ch;
    padding-bottom: .5em;
    font-preset: 1em light;
    color: conf(color, warn);
    border-bottom: 1px solid conf(color, warn);
  }

  .forms-screen__wrap__header__form-table__form-labels {
    font-preset: 0.8em normal;
    color: conf(color, white);
    background-color: conf(color, black);
    display: flex;
    flex-direction: row;
    padding: 1.2em 3em 1.2em 3em;
    border-radius: 2px;
  }

  .forms-screen__wrap__header__form-table__form-labels__dropdown {
    width: 30px;
  }

  .forms-screen__wrap__header__form-table__form-labels__practice-reference {
    flex: 7;
    text-align: left;
  }
  
  .forms-screen__wrap__header__form-table__form-labels__form {
    flex: 5;
    text-align: left;
  }

  .forms-screen__wrap__header__form-table__form-labels__appointment {
    width: 180px;
    text-align: left;
  }

  .forms-screen__wrap__header__form-table__form-labels__amount {
    width: 100px;
    text-align: left;
  }

  .forms-screen__wrap__header__form-table__form-labels__status {
    width: 120px;
    text-align: left;
  }

  .forms-screen__wrap__header__form-table__form-labels__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100px;
  }

  .form-data {
    width: 100%;
    font-preset: 0.8em light;
    color: conf(color, black);
    background-color: conf(color, white);
    display: flex;
    flex-direction: row;
    padding: 1em 3em 1em 3em;
    border-radius: 2px;
  }

  .form-data--empty {
    font-preset: 1em light;
    text-align: center;
    padding-top: 2em;
  }

  .filter-pane {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 500px;
    transform: translateX(500px);
    display: flex;
    flex-direction: column;
    transition: transform .2s ease;
    z-index: 70;
  }

  .filter-pane__blind {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: #333333ef;
    transition: opacity .2s ease;
    opacity: 0;
    pointer-events: none;
    z-index: 60;
  }

  .filter-pane__blind--shown {
    width: 100%;
    opacity: 1;
    pointer-events: initial;
  }

  .filter-pane--shown {
    transform: translateX(0);
    background: #ffffff;
    box-shadow: -2px 0px 20px #4877bd82;
  }

  .filter-pane__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1ch 0;
  }

  .filter-pane__action {
    padding: 1ch;
    margin-left: 1em;
    cursor: pointer;
  }

  .filter-pane__action-icon {
    width: 30px;
    height: 30px;
  }

  .filter-pane__action-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 20px;
    margin: 0 .5ch;
    padding-bottom: .5em;
    font-preset: 1em light;
    color: conf(color, warn);
    border-bottom: 1px solid conf(color, warn);
  }

  .filter-pane__fields {
    flex: 1;
    padding: 1ch 2em;
    overflow: auto;
  }
`;

CustomStyleSheet.create(styles, scope);
