import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const editorModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', { 'script': 'sub' }, { 'script': 'super' }, 'clean'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'align': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'blockquote'],
  ],
};

export function Editor(props) {
  const { notes, htmlText } = props;

  const onModelChange = html => {
    htmlText(html);
  };

  return (
    <div className='editor'>
      <ReactQuill modules={editorModules} theme='snow' value={notes} onChange={onModelChange} />
    </div>
  );
}

const scope = '.editor';
const styles = `
  {
    width: 100%;
  }

  .ql-container {
    height: calc(100vh - 120px - 42px);
  }
`;

CustomStyleSheet.create(styles, scope);
