import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

import { Spinner } from './spinner';

export function FullScreenLoader() {
  return (
    <div className='fsloader'>
      <Spinner size='80px' thickness='5px' />
    </div>
  );
}

const scope = '.fsloader';
const styles = `
  {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

CustomStyleSheet.create(styles, scope);
