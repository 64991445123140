import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

const CONFIG = {
  color: {
    warn: '#FF9999',

    primary: '#3299FA',
    lightPrimary: '#7dbefa',
    litPrimary: '#a9d4fc',
    lighterPrimary: '#c8e2fa',

    secondary: '',
    lightSecondary: '',
    lighterSecondary: '',

    green: '#2DCA2D',
    darkGreen: '#0F950F',

    white: '#FFFFFF',
    opaqueWhite: '#FFFFFFAA',

    lightestGrey: '#FEFEFE',
    lighterGrey: '#FAFAFA',
    litGrey: '#EFEFEF',
    lightGrey: '#DEDEDE',

    grey: '#AFAFAF',

    darkGrey: '#AAAAAA',
    darkerGrey: '#777777',
    darkestGrey: '#555555',

    black: '#333333',
  },
  spacing: {
    smallest: '.5em',
    smaller: '1em',
    small: '1.5em',
    medium: '2em',
    large: '3em',
    larger: '4em',
    largest: '6em',
  },
};

function conf(category, property) {
  const unknown = `unknown[${category}.${property}]`;

  try {
    const prop = CONFIG[category][property];

    return String(prop) || unknown;
  } catch (e) {
    return unknown;
  }
}

CustomStyleSheet.defineGlobalFunction('conf', conf);
