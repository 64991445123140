import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

export function Spinner(props) {
  const { size = '16px', thickness = '3px', color = 'primary' } = props;

  const containerStyle = { width: size, height: size };
  const divStyle = { borderWidth: thickness };

  return (
    <div className='core-spinner'>
      <div className={['lds-ring', `lds-ring--color-${color}`].join(' ')} style={containerStyle}>
        <div style={divStyle} />
        <div style={divStyle} />
        <div style={divStyle} />
        <div style={divStyle} />
      </div>
    </div>
  );
}

const scope = '.core-spinner';
const styles = `
  {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
  }

  .lds-ring--color-primary {
    --lds-ring-color: conf(color, primary);
  }

  .lds-ring--color-light {
    --lds-ring-color: conf(color, white);
  }

  .lds-ring--color-dark {
    --lds-ring-color: conf(color, darkestGrey);
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 8px solid var(--lds-ring-color);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--lds-ring-color) transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

CustomStyleSheet.create(styles, scope);
