import FirebaseAuth from '@prahatech/util-react-web/auth/FirebaseAuth';

const firebaseConfig = {
  apiKey: 'AIzaSyCJyqg_uXZ3aZ9QfgNBkpb7En2NaWoIWjY',
  authDomain: 'prahatech-drs.firebaseapp.com',
  projectId: 'prahatech-drs',
  storageBucket: 'prahatech-drs.appspot.com',
  messagingSenderId: '461551712218',
  appId: '1:461551712218:web:49f4a90358cb2b32d072e3',
  measurementId: 'G-MC4Z2BEJSE',
};

const messages = {
  auth: 'Authentication failed. Please ensure that the correct details are provider or contact an administrator.',
  configFailure: 'A fatal error has occurred when attempting to load the application. Please contact an administrator.',
  unauthorized: 'User authentication could not be verified. Please sign in.',
};

let isReady = false;

export class AuthenticationProvider {
  static async firebase() {
    return FirebaseAuth.create(firebaseConfig).catch(e => {
      console.error(messages.configFailure, e);
      return null;
    });
  }

  static async login(email, password) {
    const result = {
      ok: false,
      errors: [],
    };

    const firebase = await AuthenticationProvider.firebase();

    if (!firebase) {
      result.errors = [messages.configFailure];

      return result;
    }

    try {
      await firebase.login(email, password);

      result.ok = true;

      return result;
    } catch (e) {
      result.errors = [messages.auth, e.message];

      return result;
    }
  }

  static async isAuthenticated() {
    const firebase = await AuthenticationProvider.firebase();

    if (!firebase) return false;

    try {
      return firebase.isAuthenticated();
    } catch (e) {
      return false;
    }
  }

  static async getToken(forceRefresh = false) {
    const result = {
      ok: false,
      errors: [],
    };

    const firebase = await AuthenticationProvider.firebase();

    if (!firebase) {
      result.errors = [messages.configFailure];

      return result;
    }

    const isAuthenticated = await AuthenticationProvider.isAuthenticated();

    if (!isAuthenticated) {
      result.errors = [messages.unauthorized];

      return result;
    }

    try {
      const token = await firebase.getToken(forceRefresh);

      result.ok = true;
      result.token = token;

      return result;
    } catch (e) {
      result.errors = [messages.auth, e.message];

      return result;
    }
  }

  static async logout() {
    const result = {
      ok: false,
      errors: [],
    };

    const firebase = await AuthenticationProvider.firebase();

    if (!firebase) {
      result.errors = [messages.configFailure];

      return result;
    }

    try {
      await firebase.logout();

      result.ok = true;

      return result;
    } catch (e) {
      result.errors = [messages.auth, e.message];

      return result;
    }
  }

  static async onLogout(listener) {
    const firebase = await this.firebase();

    firebase.on('logout', listener);
  }

  static async offLogout(listener) {
    const firebase = await this.firebase();

    firebase.off('logout', listener);
  }

  static async onLogin(listener) {
    const firebase = await this.firebase();

    firebase.on('logout', listener);
  }

  static async offLogin(listener) {
    const firebase = await this.firebase();

    firebase.off('logout', listener);
  }

  static async onReady(listener) {
    if (isReady) return listener();

    const firebase = await this.firebase();

    const fn = () => {
      isReady = true;

      listener();

      firebase.off('logout', fn);
      firebase.off('login', fn);
    };

    firebase.on('logout', fn);
    firebase.on('login', fn);
  }
}

window.logout = () => AuthenticationProvider.logout();

AuthenticationProvider.firebase();
