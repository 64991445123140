import { useEffect, useState } from 'react';

import useForm from '@prahatech/util-react-web/form/useForm';
import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

import { DoctorProvider, FormProvider } from '../../providers';

import { FormSelector } from './components/form-selector';
import { FullScreenLoader } from '../../core/components';

import { navigator } from '../../navigation/app-router';

const _navigateToError = () => navigator.navigate(`/feedback/kaboom-kapow`);
const _navigateToViewForms = () => navigator.navigate('/forms');

export function CreateFormScreen() {
  const form = useForm(state => {
    setFormState(state);

    const query = state.values.query;

    _filterForms(query, formConfigs);
  });

  const [formState, setFormState] = useState(form.getState());

  const [isSettingUp, setIsSettingUp] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [formConfigs, setFormConfigs] = useState([]);
  const [visibleFormConfigs, setVisibleFormConfigs] = useState(formConfigs);

  const [selected, setSelected] = useState(undefined);

  const _selectForm = config => {
    setSelected(config);
  };

  const _createAndSendForm = async ({ code, practiceReference, email, contact, appointment, amount }) => {
    setIsLoading(true);

    const result = await FormProvider.createForm(practiceReference, email, contact, appointment, amount, code);

    if (!result.ok) {
      _navigateToError();
      return;
    }

    _navigateToViewForms();

    setIsLoading(false);
  };

  const _filterForms = (query, configs) => {
    if (!query) {
      setVisibleFormConfigs(configs);
      return;
    }

    if (!configs) {
      setVisibleFormConfigs([]);
      return;
    }

    const visible = [];

    for (let i = 0; i < configs.length; i++) {
      const config = configs[i];

      const label = config.label;

      if (label.toLowerCase().includes(query.toLowerCase())) visible.push(config);
    }

    setVisibleFormConfigs(visible);
  };

  useEffect(() => {
    Promise.resolve()
      .then(() => DoctorProvider.getConfigs())
      .then(result => {
        if (!result.ok) {
          _navigateToError();
          return;
        }

        const configs = result.formConfigs;

        setFormConfigs(configs);
        _filterForms(formState.values.query, configs);

        setIsSettingUp(false);
      });
  }, [null]);

  const _renderForms = (forms = []) => {
    const $left = [];
    const $right = [];

    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      const $side = i % 2 ? $right : $left;

      const isSelected = selected === form;

      const _selectSelf = mode => (mode === 'view' ? _selectForm(undefined) : _selectForm(form));

      $side.push(
        <FormSelector
          key={form.hash}
          config={form}
          mode={isSelected ? 'input' : 'view'}
          isLoading={isLoading}
          onToggleMode={_selectSelf}
          onSubmit={_createAndSendForm}
        />
      );
    }

    return [$left, $right];
  };

  const Field = form.Field;

  const [$formsLeft, $formsRight] = _renderForms(visibleFormConfigs);

  document.title = 'docpay - Create form';

  return isSettingUp ? (
    <FullScreenLoader />
  ) : (
    <div className='create-form-screen'>
      <header>
        <h1>Choose a form</h1>

        <Field type='text' name='query' label='Search' />
      </header>

      <div className='forms'>
        <div className='forms-container'>
          {!$formsLeft.length && !$formsRight.length && (
            <div>None of your configured forms meet your search criteria.</div>
          )}

          <div className='forms__left'>{$formsLeft}</div>

          <div className='forms__right'>{$formsRight}</div>
        </div>
      </div>
    </div>
  );
}

const scope = '.create-form-screen';
const styles = `
  {
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    font-preset: 1em light;
  }

  header {
    padding: 0 6em;
    padding-bottom: 1ch;
  }

  h1 {
    margin: 0;
    padding: 1em 0;
    color: conf(color, primary);
    font-preset: 1.5em light;
  }

  .forms {
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 0;
  }

  .forms-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 3ch 6em;
  }

  .forms__left {
    flex: 1;
    height: auto;
    padding-right: 1.5ch;
  }

  .forms__right {
    flex: 1;
    height: auto;
    padding-left: 1.5ch;
  }
`;

CustomStyleSheet.create(styles, scope);
