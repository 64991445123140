import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';
import { Tooltip } from '../../../core/components/tooltip';

export function TableRowAction(props) {
  const { tooltipText, actionChildren, onClick, className, displayCondition = true } = props;

  return (
    displayCondition && (
      <div className='action-icon'>
        <Tooltip text={tooltipText} onClick={onClick} className={className}>
          {actionChildren}
        </Tooltip>
      </div>
    )
  );
}

const scope = '.action-icon';
const styles = ``;

CustomStyleSheet.create(styles, scope);
